.contenedorPrincipalDomicilio {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.datosContenedor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
}

.datosContenedor p{
    display: flex;
    align-items: center;
}

.datosDireccion {
    width: 70%;
}

.contenedorPrincipalDomicilio p {
    text-align: left;
    font-size: 1.5rem;
    font-weight: bold;
    max-width: 70%;
}

.contenedorPrincipalDomicilio p span {
    color: var(--colorRojo);
}

.editarDireccion {
    background-color: var(--colorRojo);
    border: none;
    border-radius: 3px;
    height: 3rem;
    width: 10rem;
    margin: auto 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    transition: transform 0.1s;
}

.editarDireccion:active {
    transform: scale(0.97);
}

@media(max-width: 820px) {
    .contenedorPrincipalDomicilio p {
        font-size: 2.2rem;
    }

    .editarDireccion {
        height: 5rem;
        width: 15rem;
        font-size: 2.2rem;
    }
}

@media(max-width: 767px) {
    .contenedorPrincipalDomicilio p {
        font-size: 2.5rem;
    }

    .editarDireccion {
        height: 8rem;
        width: 15rem;
        font-size: 2.5rem;
    }
}