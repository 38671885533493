.ordenarPor{
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: -1.5rem;
    position: relative;
    z-index: 4;
}

.textoOrdenarPor{
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    margin-right: 1rem;
    padding-top: 1rem;
}

.bodyOrdenarPor{
    display: flex;
    flex-direction: row;
    position: relative;
}

.botonesArriba, .botonesAbajo{
    display: flex;
    flex-direction: row;
}

.botonesArriba{
    margin-right: 0.4rem;
}

.ordenamiento{
    width: 10rem;
    height: 2rem;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: background-color 0.2s, color 0.2s;
    user-select: none;
    margin: 0 2px;
    color: var(--colorSecundario);
}

.ordenamiento.desktop:hover{
    color: black;
}

.ordenamiento.desktop.ordenamientoActivo:hover{
    color: white;
}

.ordenamiento:first-child{
    margin-left: 0;
}

.ordenamiento:last-child{
    margin-right: 0;
}

.ordenamientoActivo{
    background-color: var(--colorSecundario);
    color: white;
}

.ordenamiento p{
    font-size: 1.5rem;
    font-weight: 600;
}

@media (max-width: 820px){
    .ordenarPor{
        margin-top: 1rem;
    }

    .textoOrdenarPor{
        font-size: 2rem;
    }

    .bodyOrdenarPor{
        height: 100%
    }

    .ordenamiento{
        height: 100%;
        width: 12rem;
        display: flex;
        align-items: center;
    }

    .ordenamiento p{
        font-size: 2rem;
    }

    .botonesArriba{
        margin-right: 0;
    }
}

@media (max-width: 767px){
    .ordenarPor{
        flex-direction: column;
        width: 100%;
    }

    .bodyOrdenarPor{
        width: 100%
    }

    .textoOrdenarPor{
        font-size: 2.7rem;
    }

    .ordenamiento{
        height: 5rem;
        width: 33%;
    }

    .ordenamiento p{
        font-size: 2.5rem;
    }

    .textoOrdenarPorContainer{
        width: 100%;
    }

    .bodyOrdenarPor{
        display: flex;
        flex-direction: column;
    }

    .botonesArriba{
        margin-bottom: 0.6rem;
    }
}