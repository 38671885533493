.contenedorPrincipalFormulario{
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--colorSecundario);
    padding-top: 1rem;
    padding-bottom: 2rem;
    width: 90%;
    display: flex;
    justify-content: center;
}

.inputContainer{
    height: 5.2rem;
    width: 100%;
}

.inputMensaje{
    height: 9.84rem;
}

.inputContainer input{
    display: block;
    width: 30rem;
    height: 2.7rem;
    border: 2px solid var(--colorTerciario);
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.inputContainer input:focus{
    outline: none;
    border-color: black;
}

.texto_mensaje{
    width: 30rem;
    display: block;
    height: 7.57rem !important;
    border: 2px solid var(--colorTerciario);
    resize: none;
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

input::placeholder, textarea::placeholder {
    font-size: 1.2rem;
}

.comunicacion{
    padding-top: 0.5rem;
    width: 30rem;
    font-size: 1.4rem;
    font-weight: 600;
}

.labelCheckbox{
    display: flex;
    margin-top: 0.5rem;
    padding-right: 10%;
    margin-bottom: 1.5rem;
    justify-content: space-evenly;
}

.label{
    display: flex;
}

.checkbox{
    width: 2rem;
}

.textoCheckbox{
    margin-right: 0.4rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.enviarFormulario{
    border: none;
    width: 30rem;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
}

.enviarFormulario:hover{
    transform: scale(1.02);
    font-size: 1.04rem;
}

.enviarFormulario.enviado{
    background-color: #f39c9c;
}

.enviarFormulario p{
    font-size: 1.3rem;
    font-weight: 700;
    color: white;
}

.errorContacto{
    height: 2rem;
    font-size: 1.42rem;
}

.enviar_formularioContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.textoComunicacion{
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

@media (max-width: 767px){
    .inputContainer input, .texto_mensaje, .enviarFormulario{
        width: 35rem;
    }

    .contenedorPrincipalFormulario{
        font-size: 2.5rem;
        padding: 3rem 0;
    }

    .comunicacion{
        width: 24rem;
        font-size: 1.4rem;
        font-weight: 600;
    }
    
    .inputContainer{
        margin-bottom: 0rem;
        height: 9rem;
    }

    input::placeholder, textarea::placeholder {
        font-size: 2.2rem;
    }

    .texto_mensaje{
        height: 20rem !important;
    }

    .inputMensaje{
        margin-bottom: 17rem;
    }

    .comunicacion{
        font-size: 2.5rem;
        display: flex;
        align-items: center;
    }

    .labelCheckbox{
        align-items: end;
        display: flex;
        flex-direction: column;
    }

    .textoCheckbox{
        font-size: 2.5rem;
    }

    .checkboxRowReverse{
        display: flex;
        flex-direction: row-reverse;
        margin: 1rem 0;
    }

    .textoComunicacion{
        margin-right: 7rem;
        font-size: 2.5rem;
    }

    .enviarFormulario{
        height: 5rem;
        margin-top: 2rem;
    }

    .enviarFormulario p{
        font-size: 2.4rem;
    }

    .inputContainer input{
        height: 4rem;
    }

    .label input{
        width: 3rem;
    }
}