.contenedorPrincipalMuestras{
    background-color: var(--colorSecundario);
    height: calc(100vh - 8.5rem);
    width: 23.5%;
    overflow: auto;
    padding-bottom: 1.5rem;
    position: fixed;
}

.contenedorPrincipalMuestras::-webkit-scrollbar{
    width: 0;
}

.tituloMuestra{
    width: 100%;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorCuaternario);
    text-align: center;
    color: var(--colorSecundario);
    font-size: 2.5rem;
    font-weight: 600;
}

@media (max-width: 767px){
    .contenedorPrincipalMuestras{
        position: fixed;
        top: 0;
        right: 0;
        height: 100vh;
        width: 0;
        z-index: 105;
        transition: width 0.2s;
        transform: translateZ(0);
    }

    .contenedorPrincipalMuestras.open{
        width: 60%;
    }

    .tituloMuestra{
        height: 6rem;
        font-size: 4rem;
    }
}