.login-container {
    display: flex;
    flex-direction: column;
    background-image: url('../../Imagenes/ca.webp');
    background-size: contain;
    background-repeat: no-repeat;
    align-items: center;
    padding-bottom: 2rem;
}

.inputFormularioLogin {
    display: flex;
    font-size: 1.5rem;
    color: var(--colorSecundario);
    margin: 0.5rem 0;
    flex-direction: column;
    justify-content: center;
}

.inputFormularioLogin input {
    display: block;
    width: 22rem;
    height: 2.7rem;
    border: 2px solid var(--colorTerciario);
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.inputFormularioLogin label {
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--colorSecundario);
}

.botonEnviarLogin {
    border: none;
    width: 12rem;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
}

.botonEnviarLogin:active {
    scale: 0.95;
}

.errorLogin {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    height: 2rem;
    align-items: center;
    margin-top: 1rem;
    font-size: 1.5rem;
}

.botonEnviarLogin:disabled {
    background-color: var(--colorTerciario);
}

.botonLoginContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 1rem;
}

.olvideMiContraseña {
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    text-align: right;
    font-size: 1.3rem;
}

@media (max-width: 912px) {
    .formularioLogin label {
        font-size: 2rem;
    }

    .formularioLogin input {
        height: 3.5rem;
        width: 100%;
        font-size: 2rem;
    }

    .formularioLogin a {
        font-size: 1.8rem;
    }

    .botonLoginContainer button {
        width: 18rem;
        height: 3.5rem;
        font-size: 2rem;
    }
}

@media (max-width: 767px){
    .formularioLogin label{
        font-size: 2.6rem;
    }

    .formularioLogin input{
        height: 4.2rem;
        width: 40rem;
        font-size: 2.9rem;
    }

    .formularioLogin a{
        font-size: 2.5rem;
        margin-top: 1rem;
    }

    .errorLogin {
        width: 90%;
        margin: 2rem 0;
    }

    .errorLogin svg{
        width: 3rem;
        height: 3rem;
    }

    .errorLogin.errorFormulario {
        font-size: 2.6rem;
        padding: 2rem 0;
        text-align: center;
    }

    .botonLoginContainer button{
        height: 5rem;
        width: 23rem;
        font-size: 2.6rem;
    }
}