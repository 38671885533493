.contenedorEntradaConfirmarCompra input:disabled{
    background-color: var(--colorTerciario);
}

.contenedorCuitInscriptoTarjeta{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.contenedorCuitInscriptoTarjeta input:first-child{
    width: 2.7rem;
    text-align: center;
    border-width: 2px 0 2px 2px;
}

.contenedorCuitInscriptoTarjeta input:last-child{
    width: 2rem;
    text-align: center;
    border-width: 2px 2px 2px 0;
}

.contenedorCuitInscriptoTarjeta p{
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--colorSecundario);
    border-color: var(--colorTerciario);
    border-style: solid;
    border-width: 2px 0 2px 0;
    background-color: var(--colorTerciario);
    padding: 0.1rem 0.5rem 0 0.5rem;
    cursor: default;
}

.contenedorCuitInscriptoTarjeta input, .contenedorCuitInscriptoTarjeta p{
    transition: border-color 0.1s;
}

.contenedorCuitInscriptoTarjeta input.focuseado, .contenedorCuitInscriptoTarjeta p.focuseado{
    border-color: var(--colorSecundario);
    
}

label.medio{
    text-align: center;
}

@media (max-width: 820px){
    .contenedorCuitInscriptoTarjeta p{
        font-size: 2.2rem;
        height: 3.5rem;
    }
}

@media (max-width: 767px) {
    .contenedorCuitInscriptoTarjeta p{
        font-size: 2.5rem;
        height: 4.2rem;
    }
}