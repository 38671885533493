.headCardPreguntasFrecuentes{
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  border-radius: 5px 5px 0px 0;
  transition: background-color 0.2s;
  min-height: 4.5rem;
  height: auto;
  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  position: relative;
}

.headCardPreguntasFrecuentes.active{
  background-color: var(--colorSecundario);
}

.headCardPreguntasFrecuentes p, .headCardPreguntasFrecuentes input {
  font-size: 2.2rem;
  width: 93%;
  text-align: center;
  user-select: none;
  font-weight: 600;
  color: var(--colorSecundario);
  border: none;
}

.headCardPreguntasFrecuentes p{
  cursor: pointer;
}

.headCardPreguntasFrecuentes.active p, .headCardPreguntasFrecuentes.active input{
  color: white;
}

.headCardPreguntasFrecuentes input{
  transition: background-color 0.2s;
}

.headCardPreguntasFrecuentes.active input{
  background-color: var(--colorSecundario);
}

.headCardPreguntasFrecuentes button{
  border: none;
  color: var(--colorSecundario);
  background-color: transparent;
  cursor: pointer;
  transition: transform 0.2s;
}

.headCardPreguntasFrecuentes.active button{
  color: white;
}

.headCardPreguntasFrecuentes.active .botonDesplegarCard{
  transform: rotate(90deg);
}

.headCardPreguntasFrecuentes button svg{
  width: 2rem;
  height: 2rem;
}

.botonBorrarCard{
  margin-left: 1rem;
}

.botonBorrarCard:hover, .botonGuardarCard:hover{
  transform: scale(1.1);  
}

.bodyCardPreguntasFrecuentes{
  height: 0;
  overflow: hidden;
  transition: height 0.2s;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: padding 0.2s, height 0.2s;
  padding: 0 1rem;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  margin-bottom: 1.5rem;
  interpolate-size: allow-keywords;
}

.bodyCardPreguntasFrecuentes p, .bodyCardPreguntasFrecuentes textarea{
  font-size: 1.4rem;
  font-weight: 600;
  border: none;
  width: 100%;
  text-align: center;
  margin: 1rem;
}

.bodyCardPreguntasFrecuentes.active{
  height: auto;
}

@media(max-width: 767px) {
  .headCardPreguntasFrecuentes{
    min-height: 6rem;
    height: auto;
  }

  .headCardPreguntasFrecuentes p, .headCardPreguntasFrecuentes input{
    font-size: 2.8rem;
  }

  .bodyCardPreguntasFrecuentes{
    font-size: 2.4rem;
  }

  .headCardPreguntasFrecuentes button svg{
    height: 3rem;
    width: 3rem;
  }

  .bodyCardPreguntasFrecuentes p, .bodyCardPreguntasFrecuentes textarea{
    font-size: 2.3rem;
  }

  .botonAgregarPreguntaFrecuente{
    width: 20%;
    height: 5rem;
  }
  
  .botonAgregarPreguntaFrecuente svg{
    height: 3rem;
    width: 3rem;
  }
}