.contenedorPrincipalInformacion{
    padding: 3rem 0;
    display: flex;
}

.contenedorPrincipalInformacion h2{
    font-weight: 600;
}

.contenedorPrincipalInformacion p{
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--colorSecundario)
}

.ventas{
    margin-bottom: 2.5rem;
}

.facturacion{
    margin-bottom: 1.4rem;
}

.bi-whatsapp, .bi-telephone, .bi-envelope-at{
    transition: transform 0.1s;
}

.bi-envelope-at:hover, .bi-whatsapp:hover, .bi-telephone:hover{
    transform: scale(1.15);
}

.bi-whatsapp{
    fill: #1ad03f;
}

.bi-telephone{
    fill: #43853F;
}

.bi-envelope-at{
    fill: #bc3728;
}

.poligono{
    width: 1.42rem;
    height: 0.6rem;
    background-color: var(--colorRojo);
    clip-path: polygon(25% 0, 100% 0%, 75% 100%, 0% 100%);
    margin-top: 1rem;
    margin-right: 0.5rem;
}

.poligonoContainer{
    padding-bottom: 0.5rem;
}

.tituloYPoligono{
    display: flex;
    align-items: center;
}

.poligonoContainer{
    padding-right: 1rem;
    padding-top: 1rem;
}

.contenedorInfo .bi{
    margin-right: 0.5rem;
}

.divisorInfo{
    display: flex;
    flex-direction: row;
}

@media (max-width: 767px){
    .contenedorPrincipalInformacion {
        padding: 4rem 0;
    }

    .contenedorPrincipalInformacion p{
        font-size: 2.4rem;
    }

    .poligono{
        width: 2rem;
        height: 1rem;
    }

    .contenedorInfo h2{
        font-size: 3rem;
    }

    .contenedorInfo div p a svg{
        height: 3rem;
        width: 3rem;
    }
}

@media (max-width: 280px){
    .contenedorPrincipalInformacion p{
        font-size: 2.3rem;
    }
}