.contenedorPrincipalConfirmacionCodigo{
    margin: 2rem;
}

.parteUtilizableConfirmacionCodigo{
    background-color: white;
    
}

.contenedorPrincipalConfirmacionCodigo p{
    font-size: 1.5rem;
}

.inputContainerCodigo{
    display: flex;
    justify-content: center;
}

.inputContainerCodigo input{
    display: block;
    width: 22rem;
    height: 2.7rem;
    border: 2px solid var(--colorTerciario);
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.inputContainerCodigo input::placeholder{
    font-size: 1.5rem;
}

.botonContainerCodigo{
    width: 100%;
    display: flex;
    justify-content: center;
}

.botonContainerCodigo button{
    margin: 1rem 1rem;
    font-size: 1.3rem;
}

.tituloConfirmacionCodigo h2{
    text-align: center;
    font-weight: 600;
    background-color: var(--colorSecundario);
    color: white;
    padding: 1rem;
}

.textoConfirmacionCodigo{
    padding: 0 3rem;
    font-weight: 700;
    color: var(--colorSecundario)
}

.textoConfirmacionCodigo p span{
    color: var(--colorRojo);
}

.formConfirmacionCodigo{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.errorConfirmacionCodigoContainer{
    height: 2rem;
    margin-bottom: 1rem;
}

.botonContainerCodigo button{
    border: none;
    width: 10rem;
    height: 3rem;
    color: white;
    font-size: 1.3rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
}

.botonContainerCodigo button p{
    font-size: 1.3rem;
}

.botonContainerCodigo button:disabled{
    background-color: var(--colorTerciario);
}

@media(max-width: 767px){
    .tituloConfirmacionCodigo h2{
        font-size: 3rem;
    }

    .contenedorPrincipalConfirmacionCodigo p{
        font-size: 2.5rem;
    }

    .inputContainerCodigo input{
        height: 5rem;
        width: 30rem;
        font-size: 3rem;
    }

    .inputContainerCodigo input::placeholder{
        font-size: 2.5rem;
    }

    .errorConfirmacionCodigoContainer{
        margin: 1rem 0;
    }

    .errorFormulario {
        font-size: 2.5rem;
        padding-bottom: 1rem;
    }

    .botonContainerCodigo{
        margin-top: 2rem;
    }

    .botonContainerCodigo button{
        width: 17rem;
        height: 5.5rem;
    }

    .botonContainerCodigo button, .botonContainerCodigo button p{
        font-size: 2.4rem;
    }
}