.contenedorFormConfirmarCompra h1{
    text-align: center;
    font-weight: 700;
    color: var(--colorSecundario);
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
}

.contenedorPequeñoTarjeta{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contenedorPequeñoTarjeta div{
    display: flex; 
    flex-direction: row;
    justify-content: center;
}

.contenedorNumerosTarjeta div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1rem;
}

.contenedorNumerosTarjeta div input{
    width: 20%;
    text-align: center;
}

.contenedorNumerosTarjeta div input:first-child{
    border-width: 2px 0 2px 2px;
}

.contenedorNumerosTarjeta div input:nth-child(3), .contenedorNumerosTarjeta div input:nth-child(5){
    border-width: 2px 0 2px 0;
}

.contenedorNumerosTarjeta div input:last-child{
    border-width: 2px 2px 2px 0;
}

.inputPequeñoTarjeta{
    text-align: center;
}

.contenedorInputCaducidad .inputPequeñoTarjeta:first-child{
    border-width: 2px 0 2px 2px;
    /*text-align: right;*/
}

.contenedorInputCaducidad .inputPequeñoTarjeta:last-child{
    border-width: 2px 2px 2px 0px;
}

.contenedorInputCaducidad.inputDosNumeros.inputMedio{
    border-width: 2rem 0 2rem 0;
}

.contenedorPequeñoTarjeta div .inputPequeñoTarjeta.inputDosNumeros{
    width: 2.7rem;
}

.contenedorInputCaducidad .focuseado, .contenedorNumerosTarjeta .focuseado{
    border-color: var(--colorSecundario);
}

.contenedorInputCaducidad p, .contenedorNumerosTarjeta div p{
    font-size: 2rem;
    border-width: 2px 0 2px 0;
    border-color: var(--colorTerciario);
    border-style: solid;
    height: 2.7rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: var(--colorSecundario);
    transition: border-color 0.2s;
    user-select: none;
}

.contenedorPequeñoTarjeta .labelTarjeta{
    margin: 0;
}

.contenedorPequeñoTarjeta div .inputPequeñoTarjeta{
    width: 30%;
}

.inputPequeñoTarjeta.inputDosNumeros.inputMesNacimiento{
    border-width: 2px 0 2px 0;
}

.contenedorDniTarjeta .inputPequeñoTarjeta{
    width: 8rem;
    margin: 0 auto;
}

.contenedorDniTarjeta label{
    text-align: center;
}

.contenedorEntradaConfirmarCompra .inputNombreYApellidoTarjeta{
    width: 24.5rem;
    margin: 0 auto 1rem auto;
}

@media (max-width: 820px){
    .contenedorFormConfirmarCompra h1{
        font-size: 3rem;
    }

    .contenedorInputCaducidad p, .contenedorNumerosTarjeta div p {
        height: 3.5rem;
        font-size: 3rem;
    }

    .contenedorPequeñoTarjeta div .inputPequeñoTarjeta.inputDosNumeros{
        width: 3.4rem;
    }

    .contenedorEntradaConfirmarCompra .inputNombreYApellidoTarjeta{
        width: 36rem;
    }

    .contenedorDniTarjeta .inputPequeñoTarjeta{
        width: 11rem;
    }
}

@media (max-width: 767px){
    .contenedorFormConfirmarCompra h1{
        font-size: 3.5rem;
    }

    .contenedorInputCaducidad p, .contenedorNumerosTarjeta div p{
        height: 4.2rem;
        font-size: 4rem;
    }

    .contenedorPequeñoTarjeta div .inputPequeñoTarjeta.inputDosNumeros{
        width: 4rem;
    }

    .contenedorEntradaConfirmarCompra .inputNombreYApellidoTarjeta{
        width: 39rem;
    }

    .contenedorDniTarjeta .inputPequeñoTarjeta{
        width: 13rem;
    }
}