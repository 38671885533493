.colapsableEditarContraseña{
    align-items: center;
    flex-direction: column;
    position: relative;
}

.formEditarContraseña{
    margin: 1rem 0;
}

.formEditarContraseña input{
    width: 100%;
}

.colapsableEditarContraseña.open{
    height: 26rem;
}

.formularioEditarContraseña{
    width: 100%;
}

@media (max-width: 912px){
    .colapsableEditarContraseña.open{
        height: 30rem;
    }
}

@media (max-width: 540px){
    .colapsableEditarContraseña.open{
        height: 40rem;
    }
}