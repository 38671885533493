.contenedorPrincipalInfoYContacto{
    background-color: var(--colorPrimario);
    display: inline;
}

.containerInfoYContactoAux{
    width: 100%;
    display: flex;
    justify-content: center;
}

.containerInfoYContacto{
    background-color: white;
}

.containerInfoYContactoAux2{
    width: 78.5%;
    display: flex;
    justify-content: center;
}

.containerInfoYContacto .col-12{
    height: 6.88rem;
    padding: 0;
    margin-left: -0.05rem;
}

.containerInfoYContactoAux2 .row{
    width: 100%;
}

.divsInfoYContacto{
    display: flex;
    justify-content: center;
}

.decoracion{  
    background: linear-gradient(90deg, var(--colorTerciario) 0%, var(--colorTerciario) 58.79%, var(--colorCuaternario) 58.79%, var(--colorCuaternario) 59.51%, var(--colorSecundario) 59.51%, var(--colorSecundario) 100%);
    width: 82.27%;
    height: 100%;
    clip-path: polygon(0% 0% , 100% 0%, calc(100% - 3.5rem) 100%, 0% 100%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: end;
    color: white;
    font-size: 2.5rem;
    padding-right: 6%;
    font-weight: 500;
}

.divInformacion{
    background: linear-gradient(90deg, white 0%,white 96.65%, var(--colorPrimario) 96.65%, var(--colorPrimario) 98%, white 98%, white 100%);
}

.redesSocialesContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5rem;
    position: relative;
}

.redesSocialesContainer .bi{
    margin: 0 2rem;
    color: var(--colorSecundario);
    transition: color 0.3s;
}

.redesSocialesContainer .bi-facebook:hover{
    color: #1773ea;
}

.redesSocialesContainer .bi-instagram:hover{
    color: #f63b3e;
}

.linkTYC{
    font-size: 1.5rem;
    position: absolute;
    right: 15rem;
    bottom: 1rem;
}

@media (max-width: 912px){
    .redesSocialesContainer{
        height: 6rem;
    }

    .redesSocialesContainer svg{
        height: 4rem;
        width: 4rem;
    }
}

@media (max-width: 767px){
    .divInformacion{
        background: white;
        padding-left: 4.1%
    }

    .decoracion{
        justify-content: end;
        width: 93%;
        background: linear-gradient(90deg, var(--colorTerciario) 0%, var(--colorTerciario) 51.79%, var(--colorCuaternario) 51.79%, var(--colorCuaternario) 52.51%, var(--colorSecundario) 52.51%, var(--colorSecundario) 100%);
        padding-right: 6rem;
        font-size: 3.2rem;
        height: 8.5rem;
    }

    .extraDecoracion{
        height: 6.9rem;
        padding-right: 3.5rem;

    }

    .containerInfoYContactoAux2{
        width: 95%;
        display: flex;
    }

    .decoracionContainer{
        padding: 0 !important;
    }

    .redesSocialesContainer{
        height: 7rem;
    }

    .redesSocialesContainer svg{
        width: 5rem;
        height: 5rem;
    }

    .linkTYC{
        font-size: 2.5rem;
        right: 1rem;
    }
}