.labelRubros {
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 550;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    background-color: white;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    color: var(--colorSecundario);
    transition: background-color 0.2s, color 0.2s, height 0.3s;
}

.labelRubros div {
    width: 95%;
    display: flex;
    justify-content: end;
}

.labelRubros .check {
    width: 0;
    height: 0;
    float: left;
}

.labelRubros.checked {
    background-color: var(--colorRojo);
    color: white;
    user-select: none;
}

.labelRubros.desplegable.checked {
    padding-bottom: 0.6rem;
}

.labelRubros:hover .textoRubro {
    color: black;
    transition: color 0.2s;
}

.labelRubros.checked:hover .textoRubro {
    color: white;
}

.bi-caret-down-fill {
    transition: transform 0.2s !important;
}

.labelRubros .textoRubro .bi-caret-down-fill,
.labelSrubro .flechaSrubroContainer svg {
    transform: rotate(270deg);
}

.labelRubros.checked .textoRubro .bi-caret-down-fill,
.labelSrubro.checked .flechaSrubroContainer svg {
    transform: rotate(360deg);
}

.textoLabelRubros:hover {
    color: black;
}

.textoLabelRubros.checked:hover {
    color: var(--colorTerciario);
}

.check {
    width: 15px;
    margin-right: 1rem;
}

.srubroCheck {
    width: 0;
    height: 0;
    display: none;
}

.textoRubro {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.nombreRubro {
    width: 90%;
}

.flechaRubro {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 820px) {
    .labelRubros {
        font-size: 2.5rem;
    }
}

@media (max-width: 767px) {
    .labelRubros {
        font-size: 4.2rem;
    }

    .flechaRubro svg, .flechaSrubroContainer svg{
        width: 3rem;
        height: 3rem;
    }
}