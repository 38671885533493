.contenedorPrincipalCortinas {
    display: flex;
    position: relative;
    margin-top: 0.6rem;
    width: 100%;
    height: 98.7%;
}

.parteFormulariosCortinas {
    font-size: 1.5rem;
    position: relative;
    z-index: 20;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.formularioCortinasContainer {
    display: flex;
    justify-content: center;
}

.decoracionCortinasContainer {
    width: 100%;
    height: 8rem;
}

.decoracionCortinas {
    background: linear-gradient(90deg, var(--colorTerciario) 0%, var(--colorTerciario) 46%, var(--colorCuaternario) 46%, var(--colorCuaternario) 48%, var(--colorSecundario) 48%, var(--colorSecundario) 100%);
}

.form-group-cortinas {
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
}

.form-group-cortinas div {
    display: flex;
    flex-direction: row;
}

.form-group-cortinas p {
    font-weight: 600;
    color: var(--colorSecundario)
}

.especificacionCortina {
    padding: 0 1rem;
    height: 3rem;
    background-color: var(--colorPrimario);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    color: var(--colorSecundario);
    margin: 0 0.5rem;
    cursor: pointer;
    user-select: none;
    transition: color 0.2s;
    border: none;
}

.especificacionCortina:not(.textoEspecificacionCortina, .milimetrosCortinas) {
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
}

.milimetrosCortinas {
    padding-right: 0.5rem;
    padding-left: 0;
    background-color: white;
    border-style: solid;
    border-width: 2px 2px 2px 0;
    border-color: var(--colorPrimario);
    cursor: text;
    margin-left: 0;
}

.milimetrosCortinas p {
    width: 100%;
    text-align: left;
}

.textoEspecificacionCortina {
    margin-right: 0;
}

.campotextoEspecificacionCortina {
    border-style: solid;
    border-width: 2px 0 2px 0;
    border-color: var(--colorPrimario);
    width: 5rem;
    text-align: right;
    color: var(--colorSecundario);
    font-weight: 600;
    padding-right: 0.2rem;
}

.campotextoEspecificacionCortina:focus {
    outline: none;
}

.campotextoAclaraciones {
    border: 2px solid var(--colorPrimario);
    height: 10rem;
    resize: none;
    color: var(--colorSecundario);
    font-weight: 600;
    padding: 0.5rem;
    width: 35rem;
}

.especificacionCortina:hover {
    color: black;
}

.especificacionCortina.checked:hover {
    color: white;
}

.especificacionCortina.disabled {
    background-color: var(--colorTerciario);
    cursor: default;
    color: var(--colorCuaternario)
}

.bodyFormGroupCortinas .checked {
    background-color: var(--colorSecundario);
    color: var(--colorTerciario);
}

.botonEnviarConsultaContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.botonEnviarConsulta {
    border: none;
    width: 15rem;
    height: 3.5rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
    margin-bottom: 2rem;
    transition: scale 0.2s;
}

.botonEnviarConsulta:active {
    transform: scale(0.97);
}

.parteMuestrasCortinas {
    width: 31%;
    display: flex;
    justify-content: center;
}

.botonMostrarMuestras {
    position: absolute;
    width: 100%;
    top: 2.5rem;
    left: 0;
    height: 6rem;
    border: none;
    color: white;
    background-color: var(--colorSecundario);
    font-size: 2.5rem;
    transition: background-color 0.1s, color 0.1s, scale 0.1s;
    font-weight: 600;
}

.botonMostrarMuestras.open {
    background-color: white;
    color: black;
    transform: scale(0.99);
}

.colorCortina {
    background-color: var(--colorPrimario);
    margin: 0 0.5rem;
    padding: 0.3rem;
    height: 3rem;
    transition: background-color 0.2s;
}

.colorCortina.colorChecked {
    background-color: var(--colorSecundario);
}

.colorCortina.colorChecked label {
    color: white;
}

.colorCortina input {
    background-size: cover;
    border: none;
    width: 4rem;
    margin-left: 0.3rem;
}

.colorCortina label {
    font-weight: 600;
    color: var(--colorSecundario);
    cursor: pointer;
    transition: color 0.2s;
    user-select: none;
    padding: 0 0.3rem;
}

.enviarCortinaDisabled {
    background-color: #DAA2A2;
}

.textoEspecificacionCortina.conValue {
    background-color: var(--colorSecundario);
    color: white;
}

.campotextoEspecificacionCortina.conValue {
    border-color: var(--colorSecundario);
}

.milimetrosCortinas.conValue {
    border-color: var(--colorSecundario)
}

@media (min-width: 768px) and (max-width: 1024px) {
    .parteFormulariosCortinas {
        min-height: calc(100vh - 15.3rem);
    }
}

@media (max-width: 767px) {
    .contenedorPrincipalCortinas {
        padding-top: 7rem;
        min-height: calc(100vh - 23.5rem);
        height: 97%;
    }

    .parteMuestrasCortinas {
        width: 0;
        overflow: visible;
    }

    .parteFormulariosCortinas {
        width: 100%;
        top: 2.5rem;
    }

    .rowTiposContainer {
        display: flex;
        flex-direction: column !important;
    }

    .especificacionCortina {
        height: 6rem;
        font-size: 2.5rem;
        margin: 0 0.5rem;
    }

    .textoEspecificacionCortina {
        margin-right: 0;
    }

    .milimetrosCortinas {
        margin-left: 0;
        transition: border-color 0.2s;
    }

    .campotextoEspecificacionCortina {
        font-size: 2.5rem;
        transition: border-color 0.2s;
        width: 8rem;
    }

    .form-group-cortinas p {
        font-size: 2.5rem;
    }

    .campotextoAclaraciones {
        width: 50rem;
        font-size: 2.5rem;
        height: 16rem;
    }

    .botonEnviarConsulta {
        width: 30rem;
        height: 6rem;
        font-size: 2.7rem;
        margin-bottom: 2rem;
    }

    .colorCortina {
        height: 6rem;
    }

    .colorCortina label {
        font-size: 2.5rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;
    }

    .colorCortina input {
        width: 6rem;
    }

    .decoracionCortinasContainer {
        height: 15rem;
    }

    .decoracionCortinas {
        font-size: 3rem;
        padding-right: 3.5rem;
        height: 9rem;
    }
}

@media (max-width: 280px) {
    .decoracionCortinas {
        background: linear-gradient(90deg, var(--colorTerciario) 0%, var(--colorTerciario) 28%, var(--colorCuaternario) 28%, var(--colorCuaternario) 30%, var(--colorSecundario) 30%, var(--colorSecundario) 100%);
    }
}