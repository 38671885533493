.overlay{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 299;
    pointer-events: none;
}

.botonHeader {
    height: 10rem;
    width: 10rem;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    transition: 0.1s;
}

.botonHeader:active {
    transform: scale(0.8);
}

.containerBotonMobile {
    position: fixed;
    z-index: 400;
    top: 1.5rem;
    left: 1.5rem;
}

.containerContainerLogoMobile {
    width: 100%;
    position: absolute;
    top: 1.3rem;
    z-index: 3;
    height: 9rem;
}

.containerLogoMobile {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    padding-right: 6rem;
}

.containerLogoMobile img {
    width: 60%;
    object-fit: contain;
}

.containerMenuMobile {
    position: absolute;
    z-index: 300;
    height: 8rem;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
    opacity: 1;
    visibility: visible;
}

.containerMenuMobile.menuAbierto {
    height: 20rem;
    opacity: 1;
    visibility: visible;
}

.containerMenuMobile:not(.menuAbierto) {
    height: 0;
    opacity: 0;
    visibility: hidden;
}

.menu {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2.5rem;
    position: fixed;
    background-color: var(--colorCuaternario);
    top: 0;
}

.containerElementosMenu {
    width: 100%;
    display: flex;
    justify-content: center;
}

.containerLogoMenu {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
}

.containerLogoMenu img {
    width: 26rem;
    padding-top: 3rem;
}

.elemento {
    background-color: white;
    margin: 1rem 0;
    width: 26rem;
    height: 6rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: 0.1s;
    cursor: pointer;
}

.elemento:active {
    transform: scale(0.95);
}

.elemento p {
    padding-top: 1%;
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--colorSecundario);
}

.elemento.catalogos{
    display: flex;
    flex-direction: column;
    overflow: hidden;
    justify-content: left;
    padding-top: 0.5rem;
}

.elemento.catalogos svg{
    margin-bottom: 0.5rem;
    height: 2rem;
    width: 2rem;
}

.elemento.catalogos p{
    height: 6rem;
}

.elemento.catalogos.open{
    height: 17.8rem;
}

.elemento.catalogos:active{
    transform: scale(0.99);
}

.bodyElemento{
    height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.bodyElemento p{
    background-color: var(--colorSecundario);
    color: white;
    width: 90%;
    height: 5rem;
    margin: 0.2rem 0;
    border-radius: 2px;
    text-align: center;
    transition: background-color 0.2s, color 0.2s;
}

.bodyElemento p:active{
    background-color: white;
    color: var(--colorSecundario);
}

.elemento.catalogos.open:has(.bodyElemento p:active) {
    transform: scale(1);
}

.elemento.catalogos.open > .bodyElemento{
    height: 6.5rem;
}

.textoCatalogosHeaderMobileContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textoCatalogosHeaderMobileContainer p{
    margin-right: 0.5rem;
    padding-top: 0.4rem;
    text-align: center;
    width: 100%;
}

.containerContainerLogoMobile.foldTienda{
    .containerLogoMobile{
        justify-content: left;
        padding-left: 2rem;
    }

    .containerLogoMobile img{
        width: 50%
    }
}

@media (max-width: 280px){
    .containerContainerLogoMobile{
        position: absolute;
        width: 30rem;
        left: 1.5rem;
        top: 1rem;
    }

    .containerLogoMobile{
        width: 100%;
        padding: 0;
    }

    .containerLogoMobile img{
        width: 100% !important;
    }
}