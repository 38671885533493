.contenedorPrincipalEditarUsuarios{
    width: 100%;
    min-height: 100vh;
}

.contenedorPrincipalEditarUsuarios{
    padding-top: 11.1rem;
}

.contenedorBusquedaEIconoEditarUsuarios{
    width: 100%;
    display: flex;
    justify-content: center;
}

.contenedorBusquedaEIconoEditarUsuarios .busquedaEIcono{
    width: 65.6%;
}

.contenedorBusquedaEIconoEditarUsuarios .busquedaEIcono .lupaContainer{
    width: 11%;
}

@media (max-width: 767px){
    .contenedorBusquedaEIconoEditarUsuarios .busquedaEIcono{
        width: 80%;
    }
}