.desplegablePerfil {
    background-color: white;
    z-index: 101;
    position: fixed;
    border: none;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
    transition-delay: 0s;
    box-shadow: 0px 2px 11px 0px rgba(0, 0, 0, 0.3);
}

.desplegablePerfil:hover,
.desplegablePerfil.open {
    height: 7.5rem;
    transition-delay: 0.2s;
}

.desplegablePerfil.logueado:hover,
.desplegablePerfil.logueado.open {
    height: 10.8rem;
    transition-delay: 0.2s;
}

.desplegablePerfil.admin.open{
    height: 13.4rem;
}

.desplegablePerfilContainer{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.perfilNavLink{
    text-decoration: none;
}

.desplegablePerfilContainer a, .desplegablePerfilContainer div {
    font-size: 1.5rem;
    color: var(--colorSecundario) !important;
    font-weight: 650;
    text-align: center;
    cursor: pointer;
    margin: 0.3rem 0;
    cursor: pointer;
}

.desplegablePerfilContainer a:hover, .desplegablePerfilContainer div:hover {
    color: var(--colorCuaternario) !important;
}
