.slide {
  display: flex;
  z-index: 1;
  padding-top: 12rem;
  width: 100rem;
  height: 58rem;
}

.d-block.w-100.clickeable{
  cursor: pointer;
}

.contenedorPrincipalCarrusel {
  display: flex;
  justify-content: center;
}

.carousel-control-prev,
.carousel-control-next {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  line-height: 40px;
  text-align: center;
  top: 56%;
  margin: 0 2.7rem;
  z-index: 2;
  background: black;
  opacity: 0.4;
}

.carousel-control-prev:focus,
.carousel-control-next:focus {
  background: black;
  opacity: 0.4;
}

.carousel-control-prev:hover,
.carousel-control-next:hover {
  background: black;
  opacity: 0.6;
}

.divSubirArchivoCarrusel,
.divEliminarArchivoCarrusel,
.divPermisosCarrusel {
  position: absolute;
  z-index: 100;
  top: 1rem;
}

.divSubirArchivoCarrusel {
  left: 1rem;
}

.divEliminarArchivoCarrusel {
  right: 1rem;
}

.divPermisosCarrusel {
  width: 30rem;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
}

.divPermisosCarrusel button {
  width: 10rem;
  font-size: 1.5rem;
  font-weight: 700;
  border: none;
  color: var(--colorSecundario);
  background-color: var(--colorPrimario);
  margin: 0 0.5rem;
  height: 3rem;
  transition: background-color 0.2s, color 0.2s;
}

.divPermisosCarrusel button.active {
  background-color: var(--colorSecundario);
  color: white;
}

.divEliminarArchivoCarrusel label {
  font-size: 3rem;
}

.divSubirArchivoCarrusel label {
  font-size: 4.5rem;
  padding-top: 0.5rem;
}

.storageCarrusel label {
  font-weight: 400;
  background-color: rgba(0, 0, 0, 40%);
  color: rgba(255, 255, 255, 70%);
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.storageCarrusel label:hover {
  background-color: rgba(0, 0, 0, 60%);
  color: white;
}

.cargandoCarouselContainer {
  padding-top: 14rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cargandoCarousel {
  width: 6rem;
  height: 6rem;
  border-width: 4px;
  color: var(--colorSecundario);
}

.urlDestinoContainer {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top: 6rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.urlDestinoContainer input {
  display: block;
  width: 30rem;
  height: 2.7rem;
  border: 2px solid var(--colorTerciario);
  padding: 0 0.5rem;
  font-weight: bold;
  color: var(--colorSecundario);
  font-size: 1.5rem;
}

.urlDestinoContainer button{
  width: 3rem;
  border: 2px solid var(--colorTerciario);
  margin-left: 1rem;
  border-radius: 4px;
}

.urlDestinoContainer button:active{
  transform: scale(0.95);
}

.urlDestinoContainer button.true{
  background-color: green;
  border: none;
}

.urlDestinoContainer button.false{
  background-color: var(--colorRojo);
  border: none;
}

@media (min-width: 1600px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1580px;
  }
}

@media (min-width: 1900px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1880px;
  }
}

@media (min-width: 2100px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 2080px;
  }
}

@media (min-width: 2400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 2380px;
  }
}

@media (min-width: 2700px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 2680px;
  }
}

@media (min-width: 3100px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 3180px;
  }
}

@media (min-width: 3400px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 3380px;
  }
}

@media (min-width: 3840px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 3820px;
  }
}

@media (min-width: 4096px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 4076px;
  }
}

@media (max-width: 767px) {
  .carousel {
    padding-top: 14rem;
  }

  .urlDestinoContainer input {
    width: 35rem;
    height: 4rem;
    font-size: 2.3rem;
  }
  
  .urlDestinoContainer button{
    width: 4rem;
  }
}

@media (max-width: 540px) {
  .slide {
    width: 95.5%;
    height: 46rem;
  }
}