.contenedorPrincipalCartelCliente{
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 50%);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
}

.parteUtilizableCartelCliente{
    width: 30rem;
    background-color: white;
    display: flex;
    align-items: center;
    padding: 1rem;
    flex-direction: column;
}

.parteUtilizableCartelCliente h1{
    font-weight: 600;
    color: var(--colorRojo);
}

.parteUtilizableCartelCliente p{
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
}

.parteUtilizableCartelCliente .botonAceptar{
    margin-top: 1rem;
    transition: scale 0.2s;
}

.parteUtilizableCartelCliente .botonAceptar:active{
    transform: scale(0.95);
}

@media (max-width: 767px){
    .parteUtilizableCartelCliente{
        width: 80%;
    }

    .parteUtilizableCartelCliente h1{
        font-size: 3rem;
    }

    .parteUtilizableCartelCliente p{
        font-size: 2rem;
    }

    .parteUtilizableCartelCliente .botonAceptar{
        width: 15rem;
        height: 4rem;
        font-size: 2rem;
    }
}