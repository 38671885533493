.rowPreguntasFrecuentes {
  max-width: 79.6%;
  margin: 0 auto;
}

.colPar, .colImpar {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 50%;
}

.botonAgregarPreguntaFrecuente{
  width: 10%;
  height: 4rem;
  border: none;
  box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
  margin: 0 auto;
  transition: transform 0.1s;
  border-radius: 3px;
}

.botonAgregarPreguntaFrecuente:hover{
  transform: scale(1.02);
}

@media(max-width: 767px) {
  .rowPreguntasFrecuentes {
    width: 95%;
    max-width: 95%;
  }

  .colPar, .colImpar {
    width: 100%;
  }
}