.contenedorPrincipalEditarDireccion{
    width: 100%;
}

.seccionesDireccion{
    width: 100%;
    margin: 1rem 0;
}

.direccionCalleInput{
    width: 75%;
    margin-right: 1.5rem;
}

.direccionNumeroInput{
    width: 20%;
}

.calleYNumero{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.direccionCpInput{
    width: 20%;
    margin-right: 1rem;
}

.direccionLocalidadInput{
    width: 75%;
}

.cpYLocalidad{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.direccionProvinciaInput{
    width: 30rem;
}