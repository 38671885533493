.contenedorPrincipalNuestrosProductos {
    position: relative; /* Establece el contenedor como posición relativa */
    margin-top: 3rem;
    margin-bottom: 2.3rem;
    display: flex;
}

.textoNuestrosProductos {
    font-size: 2.5rem;
    font-family: rajdhani;
    color: var(--colorSecundario);
}

.contenedorh1 {
    width: 47rem;
    height: 8.6rem;
    background-color: white;
    display: flex;
    align-items: center;
    padding-top: 1rem;
    justify-content: center;
    border-top-right-radius: 3.5rem;
}

.contenedorPrincipalNuestrosProductos .corner {
    background-color: white;
    width: 5rem;
    height: 7rem;
    position: absolute;
    top: 1.6rem;
    left: 45.48rem;
    clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%);
}

@media(max-width: 767px) {
    .contenedorh1 {
        height: 10rem;
    }

    .contenedorPrincipalNuestrosProductos .corner {
        height: 8.5rem;
    }

    .textoNuestrosProductos {
        font-size: 3.2rem;
    }
}