.desplegableCatalogos {
    background-color: var(--colorSecundario);
    z-index: 101;
    position: fixed;
    width: calc(var(--widthCatalogos) - 2.7rem);
    border: none;
    overflow: hidden;
    height: 0;
    transition: height 0.3s ease-in-out;
    transition-delay: 0s;
    top: 6.1rem;
    box-shadow: 0px 2px 11px 0px rgba(122, 122, 122, 0.3);
}

.desplegableCatalogos:hover,
.desplegableCatalogos.open {
    height: 7.5rem;
    transition-delay: 0.18s;
}

.descargarCatalogosContainer{
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
}

.descargarCatalogosContainer a {
    font-size: 1.5rem;
    color: white !important;
    font-weight: 650;
    text-align: center;
    cursor: pointer;
    margin: 0.3rem 0;
}

.descargarCatalogosContainer a:hover {
    color: var(--colorCuaternario) !important;
}

@media (max-width: 4096px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.7rem + 10rem);
    }
}

@media (max-width: 2560px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.7rem + 10rem);
    }
}

@media (max-width: 1920px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.6rem + 10rem);
    }
}

@media (max-width: 1600px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.3rem + 10rem);
    }
}

@media (max-width: 1440px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.5rem + 10rem);
    }
}

@media (max-width: 1366px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.6rem + 10rem);
    }
}

@media (max-width: 1280px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.2rem + 10rem);
    }
}

@media (max-width: 1024px){
    .desplegableCatalogos {
        right: calc(var(--rightAnchoPerfil) - 1.6rem + 10rem);
    }
}