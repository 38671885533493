.form-group-cortinas .divTablillas{
    display: flex;
    flex-direction: column;
}

.tablillasArriba, .tablillasMedio{
    margin-bottom: 1rem;
}

.dimensionesArriba{
    margin-bottom: 1rem;
}

.especificacionCortinaDisabled{
    color: white;
    cursor: default;
}

.especificacionCortinaDisabled:hover{
    color: white;
}