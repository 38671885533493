.contenedorPrincipalPedidoRealizado{
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 50%);
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    z-index: 102;
}

.parteUtilizablePedidoRealizado{
    width: 30rem;
    background-color: var(--colorSecundario);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px;
}

.parteUtilizablePedidoRealizado h2{
    color: white;
    text-align: center;
    font-size: 2.1rem;
}

.parteUtilizablePedidoRealizado button{
    border: none;
    width: 12rem;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
    transition: scale 0.2s;
}

.parteUtilizablePedidoRealizado button:active{
    transform: scale(0.97);
}

.cargandoRespuesta{
    width: 6rem;
    height: 6rem;
}

@media (max-width: 820px){
    .parteUtilizablePedidoRealizado{
        width: 44rem;
    }

    .parteUtilizablePedidoRealizado h2{
        font-size: 2.6rem;
    }

    .parteUtilizablePedidoRealizado button{
        width: 15rem;
        height: 4rem;
        font-size: 2.3rem;
        margin-top: 1rem;
    }
}