.contenedorPrincipalCarrito{
    position: fixed;
    z-index: 104; 
    font-size: 1.5rem;
    right: 4rem;
    display: flex;
    align-items: end;
    flex-direction: column;
    overflow: hidden;
    right: 4.6rem;
    padding-right: 1rem;
    overflow: visible;
    max-height: 58rem;
    top: 2.2rem;
}

.contenedorPrincipalCarrito .btn-primary{
    width: 5rem;
    height: 4rem;
    border: none;
    background-color: var(--colorCuaternario);
}

.contenedorPrincipalCarrito .btn-primary:hover{
    background-color: var(--colorTerciario);
    color: var(--colorRojo);
}

.tituloYHintCarrito{
    height: 3rem;
    font-size: 1.6rem;
    font-weight: 600;
    background-color: var(--colorSecundario);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tituloYHintCarrito p{
    cursor: default;
    padding-top: 0.4rem;
}

.botonDescargarPresupuesto{
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--colorPrimario);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    transition: scale 0.2s;
    margin-left: 0.5rem;
    transition: transform 0.05s;
    border: none;
}

.botonDescargarPresupuesto:not(.disabled):active{
    transform: scale(0.95);
}

.botonDescargarPresupuesto svg{
    fill: var(--colorSecundario);
    width: 80%;
    height: 80%;
}

.botonDescargarPresupuesto.disabled svg{
    fill: var(--colorCuaternario);
    cursor: default;
}

.tituloCarrito{
    width: 90%;
    text-align: center;
    padding-left: 2rem;
}

.botonHintCarrito{
    width: 2rem;
    height: 2rem;
    background-color: var(--colorPrimario);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    transition: scale 0.2s;
    margin-right: 0.5rem;
}

.botonHintCarrito:active{
    transform: scale(0.95);
}

.botonHintCarrito p{
    color: var(--colorRojo);
    font-size: 1.6rem;
    cursor: pointer;
    user-select: none;
}

.hintCarrito{
    padding: 0.5rem;
    position: absolute;
    background-color: white;
    color: var(--colorSecundario);
    border: 2px solid var(--colorSecundario);
    z-index: 12;
    top: 9rem;
    user-select: none;
    width: 38.2rem;
    font-size: 1.5rem;
}

.hintCarrito p{
    font-weight: 700;
}

.hintCarrito p span{
    color: var(--colorRojo)
}

.form-group-agregadoRapido{
    font-weight: 600;
    max-width: 100%;
    border: 1px solid var(--colorSecundario);
    height: 2.5rem;
    padding-bottom: 0.2rem;
    padding-left: 0.06rem;
}

.form-group-agregadoRapido:focus{
    outline: none;
}

.form-group-agregadoRapido::placeholder{
    font-size: 1.4rem;
}

.form-group-agregadoRapido.valido{
    color: green;
}

.form-group-agregadoRapido.invalido{
    color: var(--colorRojo);
}

.colorAgregadoRapido{
    position: relative;
}

.sugerenciaColor {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: none;
    left: 0.2rem;
}

.textoSugerencia{
    position: relative;
}

.btn-primary .bg-danger{
    font-size: 1.5rem;
    background-color: var(--colorRojo) !important;
}

.elementosVisiblesCarrito{
    width: 40rem;
}

.carritoVacioContainer{
    border-style: solid;
    border-color: var(--colorSecundario);
    border-width: 1px 2px 2px 2px;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.carritoVacioContainer img{
    object-fit: contain;
    max-height: 55%;
    margin-right: 2rem;
}

.carritoVacioContainer p{
    font-weight: 700;
    color: var(--colorCuaternario)
}

.bodyCarrito{
    height: 0;
    transition: height 0.3s;
    overflow: auto;
    margin-top: 1rem;
    transition: height 0.3s;
    interpolate-size: allow-keywords;
}

.bodyCarrito::-webkit-scrollbar{
    width: 0;
}

.bodyCarrito.open{
    height: auto;
    box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.5);
}

.agregadoRapido{
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-color: var(--colorSecundario);
    border-width: 1px 1px 0 1px;
}

.codigoAgregadoRapido{
    width: 32%;
}

.gadoRapido{
    width: 40%;
    position: relative;
}

.cantidadAgregadoRapido{
    width: 16%;
}

.enviarCompraRapida{
    width: 10%;
    border: 1px solid var(--colorSecundario);
    background-color: var(--colorSecundario);
}

.divInternoEnviarCompraRapida{
    width: 100%;
    background-color: white;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    cursor: pointer;
}

.total{
    background-color: orange;
    font-size: 20px;
}

.textoInput{
    width: 100%;
}

.tooltip{
    height: 20px;
}

.contenedorBotonCarrito{
    display: flex;
    flex-direction: column;
}

.botonCarrito{
    background-color: var(--colorSecundario);
    border-radius: 2px;
    border: none;
    width: 3.5rem;
    height: 3.5rem;
    transition: background-color 0.2s, color 0.2s;
    margin-bottom: 0.2rem;
}

.botonCarrito .bi-cart2{
    transition: fill 0.3s;
}

.cantidadEnCarrito{
    width: 1.5rem;
    height: 1.5rem;
    background-color: var(--colorSecundario);
    color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    margin-left: 0.1rem;
    padding-top: 0.2rem;
}

.botonCarrito.desktop:hover{
    background-color: white;
}

.botonCarrito.desktop:hover .bi-cart2{
    fill: var(--colorSecundario);
}

.botonCarrito.open .bi-cart2{
    fill: var(--colorSecundario);
}

.botonCarrito.open{
    background-color: white;
}

.confirmarCarrito{
    border: none;
    background-color: var(--colorRojo);
    color: white;
    width: 100%;
    height: 3rem;
    font-size: 1.7rem;
    font-weight: 600;
}

.confirmarCarrito.mayorista{
    height: 6rem;
}

@media (max-width: 767px){
    .tituloCarrito{
        font-size: 2rem;
        padding-left: 0;
        margin-right: 3rem;
    }

    .sugerenciaColor{
        font-size: 3rem;
        font-weight: 600;
        padding-bottom: 0.1rem;
        color: var(--colorCuaternario);
    }

    .hintCarrito{
        font-size: 2rem;
    }

    .botonDescargarPresupuesto{
        margin-top: 0.6rem;
        width: 5.3rem;
        height: 5rem;
        margin-left: 0.5rem;
    }

    .contenedorPrincipalCarrito{
        position: absolute;
        right: 9rem;
        top: 2rem !important;
        max-height: 115rem;
    }

    .botonCarrito{
        width: 7rem;
        height: 7rem;
    }

    .botonCarrito .bi{
        width: 4.5rem;
        height: 4.5rem;
    }

    .botonHintCarrito{
        width: 3.8rem;
        height: 3.8rem;
    }

    .cantidadEnCarrito{
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
    }

    .elementosVisiblesCarrito{
        width: 55rem;
    }

    .colorAgregadoRapido{
        width: 38%;
    }

    .form-group-agregadoRapido{
        height: 4rem;
        font-size: 2.2rem;
        color: var(--colorSecundario);
    }

    .form-group-agregadoRapido::placeholder{
        font-size: 2.8rem;
    }

    .enviarCompraRapida{
        width: 14%;
    }

    .confirmarCarrito{
        height: 6rem;
        font-size: 2.6rem;
    }

    .confirmarCarrito.mayorista{
        height: 9rem;
    }

    .tituloYHintCarrito{
        height: 5.5rem;
    }

    .tituloYHintCarrito p{
        font-size: 3rem;
    }

    .agregadoRapido input{
        height: 5rem;
        font-size: 3rem;
    }

    .carritoVacioContainer{
        height: 13rem;
    }
}

@media (max-width: 540px){
    .divInternoEnviarCompraRapida svg{
        height: 2.6rem;
        width: 2.6rem;
    }
    
    .carritoVacioContainer{
        height: 20rem;
    }

    .carritoVacioContainer p{
        font-size: 2.5rem;
    }
}

@media (max-width: 280px){
    .bodyCarrito{
        width: 44rem;
    }

    .elementosVisiblesCarrito{
        width: 100%;
    }
}
