.contenedoresConfirmarCompra{
    position: fixed;
    top: 0;
    right: 0;
    z-index: 105;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 50%);
}

.parteUtilizableConfirmarCompra{
    background-color: white;
    width: 40rem;
    padding-bottom: 1rem;
}

.headConfirmarCompra{
    display: flex;
    flex-direction: row;
    background-color: var(--colorSecundario);
    color: white;
    position: relative;
    align-items: center;
}

.headConfirmarCompra p{
    color: white;
    font-size: 2rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
}

.headConfirmarCompra button{
    border: none;
    background-color: transparent;
}

.headConfirmarCompra button{
    margin-left: 0.5rem;
}

.headConfirmarCompra button:last-child svg{
    width: 3rem;
    height: 3rem;
}

.botonesOpcionesConfirmarCompra{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
}

.botonesOpcionesConfirmarCompra button{
    background-color: var(--colorPrimario);
    border: none;
    height: 3rem;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 0.5rem;
    transition: color 0.2s, background-color 0.2s;
    box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
}

.botonesOpcionesConfirmarCompra button:hover, .botonesOpcionesConfirmarCompra button.active{
    background-color: var(--colorSecundario);
    color: white;
}

.aclaracionesConfirmarCompra{
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0 1rem;
}

.contenedorConfirmarBoton{
    width: 100%;
    display: flex;
    justify-content: center;
}

.confirmarBoton{
    border: none;
    border-radius: 4px;
    background-color: var(--colorRojo);
    color: white;
    font-weight: 600;
    font-size: 1.6rem;
    width: 20rem;
    height: 3rem;
    margin: 1rem 0;
    transition: transform 0.1s;
    margin: 1rem auto;
}

.confirmarBoton:active{
    transform: scale(0.97);
}

.contenedorFormConfirmarCompra label, .contenedorFormConfirmarCompra div label{
    font-size: 1.6rem;
    font-weight: 600;
    margin-right: 1rem;
    font-weight: bold;
    color: var(--colorSecundario);
}

.contenedorFormConfirmarCompra input, .contenedorFormConfirmarCompra div input{
    font-weight: bold;
    display: block;
    width: 30rem;
    height: 2.7rem;
    border: 2px solid var(--colorTerciario);
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.contenedorFormConfirmarCompra.contenedorConsumidorFinal input{
    margin-bottom: 1rem;
}

.contenedorFormConfirmarCompra.contenedorInscripto label{
    width: 11.6rem;
    margin: 0 auto;
}

.contenedorFormConfirmarCompra input:focus, .contenedorFormConfirmarCompra div input:focus{
    outline: none;
    border-color: var(--colorSecundario);
}

.contenedorEntradaConfirmarCompra{
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.contenedorEntradaConfirmarCompra input{
    max-width: 100%;
}

.errorFinalizarCompra{
    width: 100%;
    text-align: center;
    color: var(--colorRojo);
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
}

@media(max-width: 820px){
    .parteUtilizableConfirmarCompra{
        width: 60rem;
    }

    .botonesOpcionesConfirmarCompra button{
        font-size: 2rem;
        height: 4.3rem;
    }

    .aclaracionesConfirmarCompra{
        font-size: 2.2rem;
    }

    .confirmarBoton{
        font-size: 2.4rem;
        width: 25rem;
        height: 4rem;
    }

    .confirmarBoton{
        height: 5rem;
        font-size: 2.2rem;
    }

    .contenedorFormConfirmarCompra label, .contenedorFormConfirmarCompra div label{
        font-size: 2.2rem;
    }
    
    .contenedorFormConfirmarCompra input, .contenedorFormConfirmarCompra div input{
        width: 38.5rem;
        height: 3.5rem;
        font-size: 2.2rem;
    }

    .headConfirmarCompra p{
        font-size: 2.6rem;
    }
    
    .headConfirmarCompra button:first-child svg{
        width: 2.6rem;
        height: 2.6rem;
    }

    .headConfirmarCompra button:last-child svg{
        width: 4.6rem;
        height: 4.6rem;
    }
}

@media(max-width: 767px){
    .parteUtilizableConfirmarCompra{
        width: 60rem;
    }

    .headConfirmarCompra{
        height: 6rem;
    }
    
    .headConfirmarCompra p{
        font-size: 3.2rem;
    }
    
    .headConfirmarCompra button:first-child svg{
        width: 3.8rem;
        height: 3.8rem;
    }

    .headConfirmarCompra button:last-child svg{
        width: 6rem;
        height: 6rem;
    }

    .botonesOpcionesConfirmarCompra button{
        font-size: 2.5rem;
        height: 5rem;
    }

    .aclaracionesConfirmarCompra{
        font-size: 2.5rem;
    }

    .confirmarBoton{
        height: 5.5rem;
        font-size: 2.5rem;
        margin-top: 0.5rem;
        width: 30rem;
    }

    .contenedorEntradaConfirmarCompra{
        width: 75%;
    }

    .contenedorFormConfirmarCompra label, .contenedorFormConfirmarCompra div label{
        font-size: 2.5rem;
    }

    .contenedorFormConfirmarCompra input, .contenedorFormConfirmarCompra div input{
        width: 45.6rem;
        height: 4.2rem;
        font-size: 2.5rem;
    }

    .errorFinalizarCompra{
        font-size: 2.2rem;
        margin: 1rem 0;
    }

    .contenedorConfirmarBoton{
        margin-top: 1rem;
    }
}