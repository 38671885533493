.imagenCategoria{
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.imagenContainerCategoria{
    width: 100%;
    height: 100%;
    position: relative;
}

.nombreCategoria{
    position: absolute;
    font-weight: bold;
    z-index: 6;
    color: white;
    font-size: 1.7rem;
    font-display: swap;
    bottom: 0;
    text-align: center;
    background-color: #efefef;
    color: var(--colorSecundario);
    height: 30%;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.nombreCategoria span{
    margin-bottom: 1rem;
}

.contenedorPrincipalCardCategoria{
    width: 34rem;
    height: 20rem;
    margin: 2%;
    transition: transform 0.3s;
    cursor: pointer;
    box-shadow: 0px 0px 11px 0px rgba(0,0,0,0.3);
}

.contenedorPrincipalCardCategoria:hover{
    transform: scale(1.05);
}

@media (max-width: 1024px){
    .nombreCategoria{
        font-size: 1.6rem;
    }
}

@media (max-width: 960px){
    .nombreCategoria{
        font-size: 1.45rem;
    }
}

@media (max-width: 767px){
    .nombreCategoria{
        font-size: 1.75rem;
    }

    .nombreCategoria span{
        height: 0.8rem;
        width: 1.6rem;
    }
}