.contenedorPrincipalFooter{
    font-size: 1.4rem;
    font-weight: 500;
    width: 100%;
    z-index: 2;
    position: relative;
    height: 3.3rem;
}

.infoFooterContainer{
    background-color: var(--colorCuaternario);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.infoContainer a{
    text-decoration: none;
}

.infoFooterContainer p{
    margin: 0;
    width: 100%;
    text-align: center;
    padding-right: 2rem;
}

@media (max-width: 767px){
    .contenedorPrincipalFooter{
        font-size: 2.5rem;
        height: 7rem;
    }

    .infoFooterContainer{
        height: 9rem;
    }
}