.busquedaEIcono {
    width: 90%;
    height: 4rem;
    margin-bottom: 0.5rem;
    border: 2px solid var(--colorCuaternario);
    display: flex;
}

.busquedaEIcono input {
    font-size: 2rem;
    width: 90%;
    border: none;
    height: 100%;
    font-weight: 550;
    color: var(--colorSecundario);
    padding-left: 0.5rem;
}

.busquedaEIcono input:focus {
    outline: none;
}

.busqueda::placeholder {
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 1024px) {
    .busqueda::placeholder {
        font-size: 1.6rem;
    }
}

@media (max-width: 820px) {
    .busquedaEIcono {
        height: 6rem;
    }

    .busquedaEIcono input {
        font-size: 2.8rem;
    }

    .busquedaEIcono input::placeholder {
        font-size: 2rem;
    }
}

@media (max-width: 767px) {
    .busquedaEIcono {
        height: 8rem;
        overflow: hidden;
    }

    .busquedaEIcono input {
        font-size: 3.4rem;
        padding-left: 1rem !important;
    }

    .busquedaEIcono input::placeholder {
        font-size: 3rem;
    }

    .filtrosYBusqueda .open {
        width: 35%;
    }

    .busquedaEIcono {
        border: none;
    }
}

@media (max-width: 540px) {
    .busqueda::placeholder {
        font-size: 1.9rem;
    }
}

@media (max-width: 280px) {
    .busqueda::placeholder {
        font-size: 1.5rem;
    }
}

@media (min-width: 1920px) {
    .busquedaEIcono input::placeholder {
        font-size: 1.6rem;
    }
}