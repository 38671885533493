.contenedorPrincipalCardFavoritos {
    background-color: white;
    width: 40rem;
    height: 20rem;
    display: flex;
    align-items: center;
    border-color: var(--colorSecundario);
    border-width: 0px 2px 2px 2px;
    border-style: solid;
}

.imagenYCodigoCardFavoritos {
    width: 60%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-image: url("../../Imagenes/marcaDeAgua.webp");
    background-size: cover;
}

.imagenCardFavoritosContainer {
    width: 100%;
    height: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagenCardFavoritos {
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    cursor: pointer;
    transition: scale 0.2s;
    user-select: none;
}

.imagenCardFavoritos:hover {
    scale: 1.05;
}

.codigoYDetalleCardFavoritosContainer {
    height: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.codigoYDetalleCardFavoritos {
    font-weight: 600;
    text-align: center;
    margin: 0;
    font-size: 1.5rem;
}

.codigoYDetalleCardFavoritos span {
    color: var(--colorRojo);
}

.restoCardFavoritos {
    width: 40%;
    height: 100%;
    padding: 1rem;
    background-color: var(--colorPrimario);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.botonQuitarFavoritoContainer {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28%;
}

.botonQuitarFavorito {
    transition: scale 0.1s;
}

.botonQuitarFavorito:active {
    scale: 0.95;
}

.botonQuitarFavorito .bi-heart {
    fill: var(--colorRojo);
}

.colorCardFavoritosContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40%;
    width: 9rem;
    padding-bottom: 1rem;
}

.botonAñadirACarritoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33%;
    width: 100%;
}

.botonAñadirACarritoContainer button {
    border: none;
    background-color: var(--colorRojo);
    color: white;
    width: 100%;
    height: 3rem;
    font-size: 1.7rem;
    font-weight: 600;
    border-radius: 4px;
    transition: scale 0.1s;
}

.botonAñadirACarritoContainer button:active {
    scale: 0.95;
}

.textoColorCardFavoritos {
    margin: 0;
    font-size: 1.3rem;
}

.colorCardFavoritos {
    font-weight: 700;
}

.textoColorCardFavoritos {
    font-size: 1.2rem;
    font-weight: 600;
}

.botonQuitarFavorito {
    fill: var(--colorRojo);
    border: none;
    background-color: transparent;
    height: 3.5rem;
    width: 3.5rem;
}

.muestraColorCardFavoritos {
    width: 10rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 1.5rem;
    padding-top: 0.2rem;
}

@media (max-width: 767px) {
    .contenedorPrincipalCardFavoritos {
        width: 100%;
        height: 25rem;
    }

    .textoColorCardFavoritos {
        font-size: 1.8rem;
    }

    .colorCardFavoritos {
        font-size: 2rem;
    }

    .muestraColorCardFavoritos {
        width: 12rem;
        height: 3rem;

    }

    .botonAñadirACarritoContainer button {
        height: 3.5rem;
        font-size: 2.2rem;
    }

    .tituloFavoritos {
        height: 4rem;
    }

    .tituloFavoritos p {
        font-size: 3rem;
    }

    .botonQuitarFavorito {
        width: 4.5rem;
        height: 4.5rem;
    }

    .textoColorCardFavoritos {
        font-size: 2rem;
    }

    .coloravoritos{
        font-size: 2.3rem;
    }

    .botonAñadirACarritoContainer button {
        height: 4rem;
        font-size: 2.4rem;
    }

    .codigoYDetalleCardFavoritos {
        font-size: 2.4rem;
    }
}

@media (max-width: 280px){
    .codigoYDetalleCardFavoritos {
        font-size: 2rem;
    }

    .botonAñadirACarritoContainer button {
        font-size: 2.2rem;
    }
}