.contenedorPrincipalCartelError{
    background-color: rgba(0, 0, 0, 50%);
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 102;
    top: 0;
    overflow: hidden;
}

.parteUtilizableCartelError{
    width: 40rem;
    height: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    flex-direction: column;
    padding: 3rem 7rem;
    border-radius: 4px;
}

.parteUtilizableCartelError h2{
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 600;
    color: var(--colorSecundario);
}

.parteUtilizableCartelError button{
    border: none;
    width: 80%;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
    margin-top: 1rem;
}

.parteUtilizableCartelError a{
    font-size: 1.4rem;
    text-decoration: underline !important;
    color: blue !important;
    cursor: pointer;
    text-align: center;
}

.parteUtilizableCartelError button:active{
    transform: scale(0.95);
}

@media (max-width: 820px){
    .parteUtilizableCartelError{
        width: 50rem;
        height: 30rem;
    }

    .parteUtilizableCartelError h2{
        font-size: 3rem;
    }

    .parteUtilizableCartelError a{
        font-size: 2.2rem;
    }

    .parteUtilizableCartelError button{
        height: 5rem;
        font-size: 2.3rem;
    }
}