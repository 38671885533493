.colapsableEditarDireccion{
    align-items: space-between;
    flex-direction: column;
}

.colapsableEditarDireccion.open{
    height: 26rem;
}

.formularioAgregarDireccion{
    width: 100%;
}

.botonEnviarDireccion{
    border: none;
    width: 100%;
    height: 3rem;
    color: white;
    font-size: 1.5rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
}

@media (max-width: 912px){
    .colapsableEditarDireccion.open{
        height: 31rem;
    }

    .botonEnviarDireccion{
        height: 5rem;
    }
}

@media (max-width: 540px){
    .colapsableEditarDireccion.open{
        height: 44rem;
    }
}