.contenedorPrincipalBody{
    z-index: 2;
    background-color: #e8e4e4;
    background-size: 60%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left 9rem;
}

.restoBody{
    z-index: 2;
    position: relative;
}

.decoracionBody{
    position: absolute;
    width: 65%;
    height: 100vh;
    background-color: #e0d4d4;
    right: 0;
    position: fixed;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100vh*0.68) 100%);
}

.decoracionDosBody{
    background-color: var(--colorSenario);
    position: fixed;
    height: 100vh;
    width: 37.7%;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100vh*0.68) 100%);
    z-index: 2;
}

@media (max-width: 767px){
    .contenedorPrincipalBody{
        /*height: 1000rem; esto es porque creo que está bug el visualizador del navegador en resoluciones chiquitas*/
        z-index: 2; /*esto es para que no me tire el warning molesto*/
    }
    
}