.contenedorPrincipalSoftware {
    background-color: white;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    padding: 1.5rem;
    position: relative;
}

.headSoftware {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3d84bc;
    height: 7rem;
    padding: 0 1.5rem;
}

.headSoftware h1 {
    color: white;
    font-weight: 600;
}

.headSoftware img {
    width: 5rem;
    margin-right: 1rem;
}

.contenedorPrincipalSoftware p {
    padding: 1.5rem 0.5rem;
    font-size: 1.5rem;
    text-align: justify;
    font-weight: 500;
}

.contenedorPrincipalSoftware h2 {
    font-size: 1.9rem;
}

.contenedorPrincipalSoftware p span {
    font-weight: 600;
}

.imagenesSoftwareContainer {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.imagenesSoftwareContainer img {
    width: 30%;
    object-fit: cover;
    cursor: pointer;
    transition: transform 0.2s;
}

.imagenesSoftwareContainer img:hover {
    transform: scale(1.1);
}

.contenedorBotonConsultarSoftware {
    width: 100%;
    display: flex;
    justify-content: center;
}

.botonEnviarConsultaSoftware {
    background-color: var(--colorRojo);
    border: none;
    border-radius: 5px;
    width: 20rem;
    height: 3rem;
    color: white;
    font-size: 1.5rem;
    font-weight: 600;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
}

.botonEnviarConsultaSoftware:hover{
    transform: scale(1.05);
}

.imagenGrandeSoftware {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 110;
}

.parteInternaImagenGrandeSoftware {
    height: 95%;
}

.parteInternaImagenGrandeSoftware img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media (max-width: 767px){
    .contenedorPrincipalSoftware {
        margin-top: 2.6rem;
    }

    .headSoftware {
        height: 15rem;
    }

    .headSoftware img {
        width: 7rem;
    }

    .headSoftware h1 {
        font-size: 3.8rem;
    }

    .contenedorPrincipalSoftware p {
        font-size: 2.3rem;
    }

    .contenedorPrincipalSoftware h2 {
        font-size: 3rem;
    }

    .parteInternaImagenGrandeSoftware {
        height: auto;
        width: 100%;
    }

    .botonEnviarConsultaSoftware {
        width: 35rem;
        height: 5.5rem;
        font-size: 2.8rem;
    }
}