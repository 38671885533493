.registro-container{
    padding-top: 1rem;
    background-image: url('../../Imagenes/ca.webp');
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0.5rem 7rem 0 7rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-position: center;
    max-height: calc(100vh - 10rem);
    overflow: auto;
    position: relative;
}

.registro-container::-webkit-scrollbar {
    width: 0;
}

.form-group-registro{
    display: flex;
    font-size: 1.5rem;
    color: var(--colorSecundario);
    flex-direction: column;
    margin: 0.5rem 0;
    position: relative;
}

.form-group-registro label{
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--colorSecundario);
}

.form-group-registro input, .desplegarProvincias, .desplegarCiudades{
    display: block;
    width: 22rem;
    height: 2.7rem;
    border: 2px solid var(--colorTerciario);
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    background-color: transparent;
    font-size: 1.5rem;
}

.desplegarProvincias, .desplegarCiudades{
    cursor: pointer;
}

.desplegarCiudades.disabled{
    cursor: default;
}

.desplegarCiudades.disabled p{
    color: var(--colorTerciario);
}

.form-group-registro input:focus{
    outline: none;
    border-color: var(--colorSecundario);
}

.registro-container form{
    padding-top: 1rem;
}

.botonRegistroContainer{
    width: 100%;
    display: flex;
    justify-content: center;
}

.botonEnviarRegistro{
    border: none;
    width: 12rem;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
}

.botonEnviarRegistro:active{
    scale: 0.95;
}

.botonRegistroContainer{
    padding-top: 1rem;
}

.botonRegistroContainer{
    margin-bottom: 2rem;
}

.provinciasDesplegadas, .ciudadesDesplegadas{
    overflow: auto;
    background-color: var(--colorTerciario);
    width: 22rem;
    position: absolute;
    z-index: 10;
    top: 4.6rem;
    border: none;
    transition: height 0.3s;
    border: none;
}

.provinciasDesplegadas.abierto, .ciudadesDesplegadas.abierto{
    border: 2px solid var(--colorSecundario);
}

.opcionProvincia, .opcionCiudad{
    display: flex;
    justify-content: center;
    background-color: white;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
    height: 2.4rem;
    overflow: hidden;
}

.opcionProvincia:hover, .opcionCiudad:hover{
    background-color: var(--colorSecundario);
    color: white;
}

.provinciasDesplegadas::-webkit-scrollbar, .ciudadesDesplegadas::-webkit-scrollbar{
    width: 0;
}

.desplegarProvincias p, .desplegarCiudades p{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cargandoRegistroContainer{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.cargandoRegistroContainer div{
    width: 10rem;
    height: 10rem;
}

.terminosYCondicionesRegistro{
    font-size: 1.4rem;
    font-weight: 500;
    text-align: center;
}

.terminosYCondicionesRegistro a{
    cursor: pointer;
}

.formRegistro{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 912px){
    .form-group-registro label{
        font-size: 2rem;
    }

    .form-group-registro input{
        height: 3.5rem;
        width: 100%;
        font-size: 2rem;
    }

    .formRegistro{
        width: 35rem;
    }

    .botonRegistroContainer button{
        width: 18rem;
        height: 3.5rem;
        font-size: 2rem;
    }

    .errorFormulario{
        font-size: 2.1rem;
    }

    .desplegarProvincias, .desplegarCiudades{
        width: 24rem;
        height: 3.5rem;
        font-size: 2rem;
    }

    .provinciasDesplegadas, .ciudadesDesplegadas{
        width: 24rem;
        top: 5.5rem;
    }

    .opcionProvincia, .opcionCiudad{
        font-size: 2rem;
        height: 3.5rem;
    }
}

@media (max-width: 767px){
    .form-group-registro label{
        font-size: 2.6rem;
    }

    .form-group-registro input{
        height: 4.2rem;
        width: 40rem;
        font-size: 2.9rem;
    }

    .errorFormulario.errorRegistro{
        font-size: 2.6rem;
        padding: 2rem 0;
    }

    .botonRegistroContainer button{
        height: 5rem;
        width: 23rem;
        font-size: 2.6rem;
    }

    .desplegarProvincias, .desplegarCiudades{
        width: 40rem;
        height: 4.2rem;
        font-size: 3rem;
    }

    .provinciasDesplegadas, .ciudadesDesplegadas{
        width: 40rem;
        top: 7.8rem;
    }

    .opcionProvincia, .opcionCiudad{
        font-size: 3rem;
        height: 4.2rem;
    }

    .terminosYCondicionesRegistro{
        font-size: 2.5rem;
    }
}