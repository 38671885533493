.contenedorPrincipalLoginYRegistro {
  position: fixed;
  top: 0;
  z-index: 101;
  background-color: var(--colorPrimario);
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  transition: width 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 50%);
}

.contenedorLoginYRegistro {
  width: 45rem;
}

.content-container {
  width: 100%;
}

.btn-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.contenedorComponenteLogin {
  width: 100%;
}

.contenedorFormularios {
  background-color: var(--colorPrimario);
}

.btn-outline-secondary {
  font-size: 1.5rem;
  height: 3.2rem;
  border-radius: 0;
  border: none;
  color: var(--colorSecundario);
  font-weight: 500;
  background-color: var(--colorPrimario);
}

input[type="radio"].btn-check:hover+label.btn-outline-secondary {
  background-color: var(--colorTerciario);
  color: black;
}

input[type="radio"].btn-check:checked+label.btn-outline-secondary {
  background-color: var(--colorSecundario);
  color: white;
}

@media (max-width: 912px){
  .contenedorLoginYRegistro{
    width: 65rem;
  }

  .radioRegistrarme, .radioIniciarSesion {
    height: 5rem;
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
 
@media (max-width: 767px){
  .btn-outline-secondary{
    height: 6rem;
    font-size: 3rem;
  }
}

@media (max-width: 280px){
  .contenedorLoginYRegistro{
    width: 97%;
  }
}