.contenedorPrincipalHeader{
    background: linear-gradient(to right, var(--colorPrimario) 3%, var(--colorQuinario) 45%);
}

.secciones {
    display: flex;
    justify-content: right;
    align-items: center;
    width: 65.8%;
}

.iconoContainer {
    padding-left: 3.8rem;
}

.perfil {
    position: relative;
    background-color: var(--colorSecundario);
    height: 3.5rem;
    margin-right: -1.2rem;
    margin-left: -0.9rem;
    display: flex;
    align-items: center;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 2.5rem 100%);
    overflow: hidden;
    z-index: 105;
    user-select: none;
}

.perfil.perfilHovered {
    height: 3.5rem;
}

.perfil::before,
.perfil::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    transition: transform 0.3s, border 0.3s ease;
    z-index: -1;
    border: 3px solid white;
}

.perfil::before {
    transform: translateX(-100%);
}

.perfil::after {
    transform: translateX(-200%);
}

.perfil.perfilHovered::before {
    transform: translateX(0%);
}

.perfil p{
    font-weight: bold;
    font-size: 1.7rem;
    text-align: center;
    color: white;
    transition: color 0.3s;
    padding-left: 3.8rem;
    max-height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 98%;
    overflow: hidden;
}

.perfil.perfilHovered p{
    color: var(--colorSecundario);
}

.seccion {
    position: relative;
    text-decoration: none;
    background: white;
    height: 3.5rem;
    margin: 0 -1rem;
    display: flex;
    align-items: center;
    clip-path: polygon(0 0, calc(100% - 2.5rem) 0, 100% 100%, 2.5rem 100%);
    overflow: hidden;
    z-index: 105;
    justify-content: center;
    user-select: none;
}

.seccion:not(.catalogosYArrow){
    cursor: pointer;
}

.seccion::before,
.seccion::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 3.5rem;
    background: var(--colorSecundario);
    transition: transform 0.3s;
    z-index: -1;
}

.seccion::before {
    transform: translateX(-100%);
}

.seccion::after {
    transform: translateX(-200%);
}

.seccion:hover:not(.catalogosYArrow)::before,
.seccion.hovered::before {
    transform: translateX(0);
}

.seccion p,
.seccion .catalogosYArrow {
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    color: var(--colorSecundario);
    margin: 0 4.8rem;
    transition: color 0.3s;
}

.seccion:hover:not(.catalogosYArrow) p,
.seccion.hovered p,
.seccion.hovered .catalogosYArrow{
    color: white;
    z-index: 1;
}

.catalogosYArrow{
    position: relative;
}

.logoContainer {
    display: flex;
    justify-content: right;
    padding-right: 6rem;
}

.logo {
    width: 57%;
    object-fit: contain;
    cursor: pointer;
}

.bi-person-fill {
    color: white;
    transition: color 0.3s;
}

.perfil.perfilHovered .bi-person-fill {
    color: var(--colorSecundario);
    z-index: 1;
}

.decoracionHeader{
    position: absolute;
    height: 100%;
}

.decoracionGris{
    width: 65%;
    background-color: var(--colorCuaternario);
    display: flex;
    justify-content: right;
}

.decoracionRoja{
    width: 58%;
    background-color: var(--colorRojo);
}

.misCompras{
    cursor: pointer;
}

@media (max-width: 4096px){
    .seccion p{
        font-size: 1.7rem;
    }
}

@media (max-width: 2560px){
    .seccion p{
        font-size: 1.7rem;
    }
}

@media (max-width: 1920px){
    .seccion p{
        font-size: 1.5rem;
    }
}

@media (max-width: 916px){
    .seccion p{
        font-size: 1.22rem;
    }   
}

