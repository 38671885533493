.contenedorPrincipalQuienesSomos{
    display: flex;
    justify-content: center;
    width: 78.5%;
    margin: auto;
    flex-direction: column;
}

.fondoContainer{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 3rem;
    position: relative;
    justify-content: left;
}

.logoQuienesSomos{
    object-fit: cover;
    height: 70%;
    align-self: flex-end;
    padding-right: 17%;
}

.imagenQuienesSomos{
    width: 83%;
    height: 29.6%;
    object-fit: cover;
    left: 0;
}

.tituloYPoligono .poligono{
    margin: 0;    
}

.tituloYPoligono h2{
    font-size: 1.7rem;
    padding-left: 0.8rem;
    font-weight: 600;
}

.textoQuienesSomos{
    padding: 4% 13% 0 10%;
    background-image: url(../../../Imagenes/CAquienesSomos.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-color: white;
    background-position: bottom right;
}

.parrafoQuienesSomos{
    padding-top: 2rem;
    padding-left: 2.5rem;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: justify;
    margin-bottom: 3.8%;
}

@media(max-width: 540px){
    .tituloYPoligono h2{
        font-size: 2.5rem;
    }

    .parrafoQuienesSomos{
        font-size: 2.2rem;
    }

    .imagenQuienesSomos{
        width: 100%;
    }
}