
.paginacion {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.buttonPag {
    margin: 2rem 0;
    border: none;
    background-color: var(--colorPrimario);
    font-weight: 650;
    height: 3rem;
    width: 7rem;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.buttonPag:hover {
    background-color: var(--colorTerciario);
}

.pagina-actual {
    background-color: var(--colorCuaternario);
}

.botonPaginacion {
    clip-path: polygon(0 0, calc(100% - 2.7rem) 0, 100% 100%, 2.7rem 100%);
    width: 10rem;
    margin: 0 -1.1rem;
}

.paginaExtremo {
    width: 5rem;
    text-align: left;
    padding-left: 0.8rem;
    margin-right: -1rem;
}

.primeraPagina {
    clip-path: polygon(0 0, calc(100% - 2.7rem) 0, 100% 100%, 0% 100%);
}

.botonAntSig {
    width: 7rem;
    clip-path: polygon(0 0, calc(100% - 2.7rem) 0, 100% 100%, 2.7rem 100%);
    margin: 0 -0.5rem;
}

.ultimaPagina {
    transform: scaleX(-1);
    clip-path: polygon(0 0, 100% 0, calc(100% - 2.7rem) 100%, 0 100%);
    margin-right: 0;
    margin-left: -1rem;
}

@media (min-width: 1920px) {
    .paginacion {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 820px) {
    .paginacion {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .paginacion {
        margin-right: 1rem;
        margin-top: -2rem;
        margin-bottom: -1rem;
        width: 100%;
    }

    .buttonPag {
        width: 15rem;
        height: 5rem;
        font-size: 2.5rem;
    }

    .ultimaPagina,
    .primeraPagina {
        width: 6rem;
    }

    .ultimaPagina svg,
    .primeraPagina svg,
    .botonAntSig svg {
        height: 2.5rem;
        width: 2.5rem;
    }

    .botonAntSig {
        width: 10rem;
    }

}

@media (max-width: 280px){
    .buttonPag {
        width: 13rem;
        height: 3.5rem;
    }

    .ultimaPagina,
    .primeraPagina {
        width: 7rem;
    }

    .botonAntSig {
        width: 9rem;
    }
}