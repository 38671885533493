.contenedorPrincipalCardEditarUsuario{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.formularioEditarUsuario{
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
}

.inputTextoEditarUsuario, .formularioEditarUsuario label{
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--colorSecundario);
}

.contenedorPartesFormulario{
    background-color: var(--colorSecundario);
}

.contenedorPartesFormulario h1{
    color: white;
    padding-left: 1.5rem;
    height: 3rem;
}

.parteArribaFormularioEditarUsuario, .parteMedioFormularioEditarUsuario, .parteAbajoFormularioEditarUsuario{
    display: flex;
    flex-direction: row;
    background-color: var(--colorPrimario);
    height: 4.5rem;
}

.formularioEditarUsuario label{
    margin: 0.5rem 1.5rem;
}

.formularioEditarUsuario label #cuit{
    width: 10rem;
}

.formularioEditarUsuario label #zona{
    width: 10rem;
}

.formularioEditarUsuario label #email{
    width: 20rem;
}

.formularioEditarUsuario label #telefono{
    width: 13rem;
}

.formularioEditarUsuario label #codigo_confirmacion{
    width: 7rem;
}

.formularioEditarUsuario label #emailConfirmado, .formularioEditarUsuario label #cliente{
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
} 

.inputTextoEditarUsuario{
    height: 3.5rem;
    margin-left: 1rem;
    border: 2px solid var(--colorSecundario);
    padding-left: 0.5rem;
}

.labelCheckboxEditarUsuario{
    display: flex;
    align-items: center;
}

.contenedorBotonEnviarUsuario{
    width: 13rem;
    display: flex;
    align-items: center;
    background: linear-gradient(to bottom, var(--colorSecundario) 0%, var(--colorSecundario) 18%, var(--colorPrimario) 18%, var(--colorPrimario) 100%);
    padding-right: 1rem;
    flex-direction: column;
    justify-content: center;
}

.contenedorBotonEnviarUsuario button:hover{
    font-size: 1.4rem;
    transform: scale(1);
}

.contenedorBotonEnviarUsuario button:first-of-type{
    margin-top: 2rem;
}

.contenedorBotonEnviarUsuario button:last-of-type{
    margin-top: 1rem;
    background-color: var(--colorCuaternario);
}

.contenedorBotonEnviarUsuario button:active{
    transform: scale(0.97);
}

.contenedorBotonEnviarUsuario .enviarFormulario{
    width: 90%;
    display: inline;
}

.parteAbajoFormularioEditarUsuario div:last-child{
    display: flex;
}

.enviarFormulario.modificado{
    background-color: green;
}

@media (max-width: 767px){
    .formularioEditarUsuario{
        flex-direction: column;
        width: 80%;
    }

    .contenedorPartesFormulario h1{
        height: auto;
        padding-top: 0.4rem;
        padding-left: 2.5rem;
        font-size: 4rem;
    }

    .parteArribaFormularioEditarUsuario, .parteMedioFormularioEditarUsuario, .parteAbajoFormularioEditarUsuario{
        height: auto;
    }

    .parteAbajoFormularioEditarUsuario, .parteMedioFormularioEditarUsuario, .parteMedioFormularioEditarUsuario label, .parteArribaFormularioEditarUsuario, .parteAbajoFormularioEditarUsuario div:first-child, .parteArribaFormularioEditarUsuario label{
        display: flex;
        flex-direction: column;
    }

    .parteAbajoFormularioEditarUsuario div:first-child label{
        flex-direction: column;
        display: flex;
    }

    .contenedorBotonEnviarUsuario{
        background: none;
        background-color: var(--colorPrimario);
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 1rem;
        align-items: center;
        flex-direction: row;
    }

    .contenedorBotonEnviarUsuario button{
        width: 20rem !important;
        height: 6rem;
        margin: 1rem 1rem !important;
        transition: transform 0.2s;
        font-size: 2.8rem;
    }

    .parteArribaFormularioEditarUsuario, .parteMedioFormularioEditarUsuario, .parteAbajoFormularioEditarUsuario{
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .parteArribaFormularioEditarUsuario label input, .parteMedioFormularioEditarUsuario label input, .parteAbajoFormularioEditarUsuario label input{
        height: 5rem;
        font-size: 2.6rem;
    }

    .parteArribaFormularioEditarUsuario label, .parteMedioFormularioEditarUsuario label, .parteAbajoFormularioEditarUsuario label{
        font-size: 2.3rem;
    }

    .parteAbajoFormularioEditarUsuario div:last-child label{
        display: flex;
        align-items: center;
        margin-top: 2rem;
    }

    .parteAbajoFormularioEditarUsuario div:last-child{
        justify-content: center;
    }

    .inputTextoEditarUsuario{
        margin: 0;
    }

    .labelCheckboxEditarUsuario{
        display: inline;
    }

    .formularioEditarUsuario label #emailConfirmado, .formularioEditarUsuario label #cliente{
        height: 3.5rem;
        width: 3.5rem;
    }

    .formularioEditarUsuario label #cuit, .formularioEditarUsuario label #telefono, .formularioEditarUsuario label #email, .formularioEditarUsuario label #zona, .formularioEditarUsuario label #codigo_confirmacion{
        width: auto;
    }
}