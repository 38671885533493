.bodyFiltro {
    display: flex;
    flex-direction: column;
    transition: height 0.3s;
    overflow: hidden;
    z-index: 2;
    width: 95%;
}

.bodyFiltro::-webkit-scrollbar {
    width: 0.5rem;
}

.bodyFiltro::-webkit-scrollbar-track {
    background: transparent;
}

.bodyFiltro::-webkit-scrollbar-thumb {
    background-color: white;
    border-radius: 20px;
    border: 0;
}

.bodyFiltroPerfil.checked {
    height: auto;
}

.labelSrubro,
.labelColor {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: var(--colorSecundario);
    transition: background-color 0.2s, border 0.2s;
    flex-direction: column;
}

.labelSrubro.checked {
    background-color: var(--colorCuaternario);
    color: white;
}

.labelSrubro.checked.conColores {
    padding-bottom: 0.7rem;
}

.labelSrubro.checked:hover .textoSrubro,
.labelSrubro.checked:hover .flechaSrubroContainer svg {
    color: white;
    fill: white;
}

.labelSrubro:not(.checked):hover .textoSrubro,
.labelSrubro:not(.checked):hover .flechaSrubroContainer svg {
    color: #1D1D1D;
    fill: #1D1D1D;
}

.bodyFiltro.bodyFiltroPerfil {
    background-color: white;
    border-style:solid;
    border-color: var(--colorPrimario);
    border-width: 0 0 1px 0;
    width: 100%;
}

.srubrosRender{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bodyFiltro.bodyFiltroPerfil:first-child, .labelMarcas:first-child{
    display: flex;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.3);
}

.bodyFiltro.bodyFiltroPerfil:last-child, .labelMarcas:last-child{
    border: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.nombreSrubro {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.textoSrubro {
    text-align: center;
    width: 90%;
    display: flex;
    padding-left: 3.8%;
}

.nombreSrubro{
    width: 90%;
}

.flechaSrubroContainer {
    width: 10%;
}

.labelColor {
    background-color: white;
    transition: color 0.2s, background-color 0.2s;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--colorTerciario);
}

.labelColor.checked {
    background-color: var(--colorSecundario);
    color: white;
}

.labelColor:last-child{
    border: none;
}

.labelColor:hover {
    color: black;
}

.labelColor.checked:hover {
    color: white;
}

.labelColor,
.textoColorFiltros {
    width: 100%;
}

.coloresSrubrosContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.labelColor:last-child{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.labelColor:first-child{
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.3);
}