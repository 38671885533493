.bodyConfirmarCompra div{
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0.5rem 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--colorPrimario);
    border-radius: 3px;
}

.bodyConfirmarCompra span{
    color: var(--colorRojo);
    font-weight: bold;
}

.bodyConfirmarCompra div:not(.contenedorConfirmarBoton) p{
    text-align: center;
}

.productosFinalizarPedido{
    display: flex;
    justify-content: space-around;
}

@media (max-width: 820px){
    .bodyConfirmarCompra div:not(.contenedorConfirmarBoton) p, .errorFinalizarCompra{
        font-size: 2.2rem;
    }
}

@media (max-width: 767px){
    .bodyConfirmarCompra div:not(.contenedorConfirmarBoton) p, .errorFinalizarCompra{
        font-size: 2.5rem;
    }
}