.contenedorPrincipalMisCompras{
    background-color: var(--colorSenario);
    display: flex;
    overflow: hidden;
}

.contenedorPrincipalMisCompras.noVacio{
    min-height: calc(100vh - 7rem);
}

.contenedorPrincipalMisCompras.vacio{
    min-height: calc(100vh - 3.3rem);
}

.decoracionMisCompras{
    background-color: var(--colorTerciario);
}

.decoracionDosMisCompras{
    background-color: var(--colorCuaternario);
}

.misComprasContainer{
    display: flex;
    flex-direction: row;
    padding-top: 10rem;
    justify-content: center;
    width: 100vw;
}

.misComprasContainer.mobile{
    flex-direction: column;
    justify-content: left;
    align-items: center;
    padding-top: 12rem;
}

.columnaImpar{
    padding-right: 7rem;
    padding-left: 1rem;
}

.columnaPar{
    padding-left: 7rem;
    padding-right: 1rem;
}

.columnaImpar, .columnaPar{
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    width: 50%;
}

.misComprasContainer.mobile > .columnaImpar, .misComprasContainer.mobile > .columnaPar{
    padding: 0;
    width: 60rem;
}

.misComprasContainer.fold > .columnaImpar, .misComprasContainer.fold > .columnaPar{
    width: 90%;
}

.historialVacioContainer{
    height: calc(100% - 12rem);
    position: absolute;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
    top: auto;
    padding-bottom: 8rem;
}

.historialVacioContainer h1{
    position: relative;
    font-size: 5rem;
    font-weight: 700;
    color: var(--colorSecundario);
    text-align: center;
}

.historialVacioContainer img{
    height: 30rem;
}

@media (max-width: 767px){
    .contenedorPrincipalMisCompras.vacio{
        min-height: calc(100vh - 9rem);
    }

    .contenedorPrincipalMisCompras.vacio .misComprasContainer .historialVacioContainer{
        padding: 0 4rem;
    }

    .historialVacioContainer{
        height: calc(100% - 12rem);
    }
}
