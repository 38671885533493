.marcasRender{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.labelMarcas{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    transition: background-color 0.2s, color 0.2s;
    background-color: white;
    border: 1px solid var(--colorPrimario);
    color: var(--colorSecundario);
}

.labelMarcas:hover{
    color: black;
}

.labelMarcas.checked:hover{
    color: white;
}

.labelMarcas.checked{
    background-color: var(--colorTerciario);
    color: white;
    padding-bottom: 0.7rem;
    border: none;
}

.labelMarcas .textoMarca svg{
    transform: rotate(270deg);
}

.labelMarcas.checked .textoMarca svg{
    transform: rotate(360deg);
}

.textoMarca{
    width: 100%;
}

.nombreMarca{
    width: 90%;
    text-align: center;
}

@media (max-width: 767px){
    .flechaMarca{
        width: 10%;
    }

    .flechaMarca svg{
        width: 3rem;
        height: 3rem;
    }
}