*{
  margin: 0;
  padding: 0;
}

html{
  background-color: var(--colorPrimario);
}

.errorFormulario{
  font-size: 1.5rem;
  color: var(--colorRojo);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin-top: 0.5rem;
  font-weight: 600;
}

.svgErrorFormulario{
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

p{
  margin: 0;
}

h1{
  margin: 0;
}

:root{
  --colorPrimario: #e5e5e5;
  --colorSecundario: #4e4e4d;
  --colorTerciario: #b8bcbc;
  --colorCuaternario: #989899;
  --colorQuinario: #a3a4a8;
  --colorSenario: #f8f4f4;
  --colorRojo: #fb0404;
  --colorAzulOscuro: #38647c;
  --colorAzulClaro: #5d8398;

  --An-Natural: #b4bcbc;
  --Natural: #b4bcbc;
  --Ind: white;
  --Varios: white;
  --Cristal: #a8d4dc;
  --Blanco: white;
  --Fume: #504434;
  --Azul: blue;
  --Verde: green;
  --Oro: #d8b43c;
  --Negro: black;
  --Zinc: #988c8c;
  --Naranja: orange;
  --Acero: #88848c;
  --Platil: #847c84;
  --Gris: #8D8D8D;
  --Anodizado: #949890;
  --Varios: #FFFFFF;
  --Incoloro: #FFFFFF;
  --Rojo: red;
  --Bronce-pul: #796a3b;
  --Bronce-pulido: #796a3b;
  --Satinado: #FFFFFF;
  --Bronce-sat: #796a3b;
  --Bronce-satinado: #796a3b;
  --Simil-madera: #94542b;
  --Guatambu: #c39454;
  --Marron-claro: #d4a86c;
  --Marron-osc: #603414;
  --Marron-oscuro: #603414;
  --Peltre: #796e6d;
  --Champagne: #f0dcbc;
  --Bronce: #685c34;
  --Bronce-medio: #40391d;
  --Bronce-osc: #281c14;
  --Bronce-oscuro: #281c14;
  --Beige: #e5c096;
  --Gris-azulado: #404c64;
  --Opalina: #c8d0d6;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input{
  transition: border-color 0.2s;
}

input:not(.sinFocus):focus {
  outline: none;
  border-color: var(--colorSecundario);
}

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

@media (max-width: 4096px){
  html{
    font-size: 32px;
  }
}

@media (max-width: 3840px){
  html{
    font-size: 17px;
  }
}

@media (max-width: 3500px){
  html{
    font-size: 16px;
  }
}

@media (max-width: 3100px){
  html{
    font-size: 15px;
  }
}

@media (max-width: 2800px){
  html{
    font-size: 14px;
  }
}


@media (max-width: 2530px){
  html{
    font-size: 13px;
  }
}

@media (max-width: 2020px){
  html{
    font-size: 12px;
  }
}

@media (max-width: 1600px){
  html{
    font-size: 10px;
  }
}

@media (max-width: 1280px){
  html{
    font-size: 8px;
  }
}

@media (max-width: 820px){
  html{
    font-size: 7px;
  }
}

@media (max-width: 767px){
  html{
    font-size: 6.5px;
  }

  .svgErrorFormulario{
    width: 3rem;
    height: 3rem;
  }
}

@media (max-width: 500px){
  html{
    font-size: 6px;
  }
}

@media (max-width: 390px){
  html{
    font-size: 5.4px;
  }
}