.contenedorPrincipalCartelLogout{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 50%);
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 101;
    top: 0;
}

.contenedorCartelLogout{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-direction: column;
    width: 30rem;
    height: 15rem;
    border-radius: 2px;
}

.contenedorCartelLogout h2{
    text-align: center;
    margin-bottom: 1rem;
    font-size: 2.7rem;
    font-weight: 600;
    color: var(--colorSecundario);
}

.contenedorCartelLogout button{
    border: none;
    width: 50%;
    height: 3rem;
    color: white;
    font-size: 1.4rem;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
    margin-top: 1rem;
}

.contenedorCartelLogout button:active{
    transform: scale(0.95);
}

@media (max-width: 767px){
    .contenedorCartelLogout{
        width: 50rem;
        height: 25rem;
    }

    .contenedorCartelLogout h2{
        font-size: 4.5rem;
    }

    .contenedorCartelLogout button{
        width: 60%;
        height: 5rem;
        font-size: 2.5rem;
    }
}