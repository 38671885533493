.filtrosYBusqueda {
    width: 20%;
    height: 100%;
    position: fixed;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: width 0.3s;
}

.filtrosYProductosContainer {
    width: 100%;
    display: flex;
    justify-content: right;
}

.productos {
    padding-right: 1rem;
    min-height: calc(100vh - 19rem);
    position: relative;
    /*z-index: 1;*/
}

.contenedorPrincipalFiltrosYProductos {
    display: flex;
    flex-direction: column;
    padding-top: 8.1rem;
    background-color: var(--colorAzulClaro);
    background-image: url('../../Imagenes/caAzul.webp');
    background-size: 60%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: left 9rem;
    min-height: calc(100vh - 3.3rem);
    align-items: center;
}

.contenedorPrincipalFiltrosYProductos.mobile {
    padding-top: 10.1rem;
}

.decoracionTienda {
    position: fixed;
    height: 100%;
    width: 65%;
    background-color: var(--colorAzulOscuro);
    right: 0;
    top: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, calc(100vh*0.68) 100%);
    z-index: 0;
}

.lupaContainer {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--colorCuaternario);
}

.lupaContainer .bi-search {
    color: white;
}

.boton {
    width: 4rem;
    height: 4rem;
    font-size: 2rem;
}

.cantidad span {
    font-size: 2rem;
}

.botonesOrdenamientoContainer {
    position: fixed;
    top: 40rem;
}

.cargandoProductos {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6rem;
    height: 6rem;
    border-width: 4px;
    color: var(--colorSecundario);
}

.containerFiltrosYBoton{
    display: flex;
    flex-direction: row;
}

.textoComunicateConNostros{
    background-color: var(--colorRojo);
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
}

.textoComunicateConNostros.textoPaneles{
    margin-top: 2.5rem;
}

@media (max-width: 1023px){
    .textoComunicateConNostros{
        margin-top: 3rem;
    }
}

@media (max-width: 820px) {
    .filtrosYBusqueda:not(.open) {
        border: none;
    }

    .botonFiltros{
        position: absolute;
        top: 33%;
        right: -5rem;
        width: 5rem;
        height: 8rem;
        border-color: transparent;
        border-radius: 0 100rem 100rem 0;
        background-color: var(--colorTerciario);
    }

    .botonFiltros svg{
        transition: transform 0.3s;
    }

    .botonFiltros.abierto svg{
        transform: rotate(180deg);
    }
}

@media (max-width: 767px) {
    .contenedorPrincipalFiltrosYProductos {
        padding-left: 1rem;
        padding-bottom: 1rem;
        min-height: calc(100vh - 9rem);
    }

    .rowProductos {
        margin-top: 3rem;
    }
    
    .productos{
        min-height: calc(100vh - 26.5rem);
        /*z-index: 1;*/
    }

    .textoComunicateConNostros.textoPaneles{
        font-size: 3rem;
        margin-top: 4rem;
    }
}

@media (max-width: 280px) {
    .filtrosYBusqueda.open {
        width: 60% !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .productos {
        min-height: calc(100vh - 21rem);
    }

    .contenedorPrincipalMuestras {
        height: calc(100vh - 15.5rem) !important;
    }
}

@media (min-width: 912px) {
    .productos {
        margin-left: 20%;
    }
}

@media (min-width: 1920px) {
    .productos .row {
        margin-top: 0.5rem;
    }
}

@media (min-width: 2560px) {
    .productos .row {
        margin-top: 1rem;
    }
}