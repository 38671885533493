.contenedorPrincipalCategorias{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    width: 95%;
}

.rowCategorias{
    margin: 0 10%;
}

.categoria{
    padding: 0;
    display: flex;
    justify-content: center;
}

@media (max-width: 767px){
    .contenedorPrincipalCategorias{
        margin-bottom: 0;
    }
}

@media (max-width: 360px) {
    .categoria {
      width: 100%;
    }

    .contenedorPrincipalCategorias{
        margin-bottom: 0;
    }
  }