.contenedorPrincipalProductoGrande {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 104;
}

.parteUtilizableProductoGrande {
    background-color: var(--colorPrimario);
    height: 55rem;
    width: 90rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem;
    background-image: url("../../../Imagenes/marcaDeAgua.webp");
    background-size: cover;
}

.parteInternaUtilizableProductoGrande {
    border-style: solid;
    border-image: linear-gradient(to right,
            transparent 0%,
            transparent 10%,
            white 10%,
            white 90%,
            transparent 90%,
            transparent 100%) 1;
    border-image-slice: 1;

    border-width: 2.5px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imagenProductoGrandeContainer{
    width: 50rem;
    height: 26rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1.5rem;
}

.imagenProductoGrande {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    user-select: none;
}

.contenedorPrincipalProductoGrande h1 {
    text-align: center;
    font-size: 5rem;
    font-weight: 600;
    color: var(--colorRojo);
}

.contenedorPrincipalProductoGrande h2 {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
}

.textoCantidad {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
}

.logoProductoGrandeContainer {
    height: 8rem;
    width: 10.5rem;
    display: flex;
    position: absolute;
    top: calc(50vh - 26rem);
    left: calc(50vw - 44rem);
    align-items: start;
}

.logoProductoGrande {
    width: 100%;
    object-fit: contain;
}

.informacion {
    padding-top: 1rem;
    height: 16rem;
}

.cantidad {
    display: flex;
    justify-content: center;
    padding: 0 8rem;
    align-items: center;
}

.cantidadProductoGrande {
    font-size: 4rem;
    font-weight: 500;
    width: 5rem;
    text-align: center;
    padding-top: 0rem;
    margin: 0 1rem;
}

.botonProductoGrande {
    width: 4rem;
    height: 4rem;
    border: none;
    display: flex;
    align-items: center;
    background-color: var(--colorRojo);
    justify-content: center;
    border-radius: 3px;
    transition: background-color 0.2s ease, color 0.2s ease;
    font-weight: 500;
    font-size: 4rem;
    color: white;
}

.coloresProductoGrande {
    width: 8rem;
}

.coloresProductoGrande .color {
    margin: 0.2rem 0;
    cursor: pointer;
}

.kgCantidadColorContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
}

.textoPesoPromedio {
    padding-top: 1rem;
    margin: 0;
    width: 100%;
    text-align: center;
}

.kgProductoGrandeContainer {
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.kgProductoGrandeContainer p {
    font-size: 2.5rem;
    font-weight: 600;
}

.conjuntoCantidad {
    width: 40%;
}

.colorProductoGrandeContainer {
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
}

.colorProductoGrandeContainer p {
    font-size: 2.5rem;
    font-weight: 600;
}

.textoColorProductoGrande {
    padding-top: 1rem;
    margin: 0;
}

.muestraColorProductoGrande {
    height: 3.5rem;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    font-size: 2.5rem;
    font-weight: 600;
    padding: 0 0.5rem;
    max-width: 30rem;
    min-width: 18rem;
}

.colorAtributo {
    width: 100%;
    text-align: center;
}

.siguienteProductoGrande, .anteriorProductoGrande{
    position: absolute;
    top: 50vh;
    border: none;
    background-color: transparent;
    transition: scale 0.1s;
}

.siguienteProductoGrande:active, .anteriorProductoGrande:active{
    transform: scale(0.95);
}

.siguienteProductoGrande{
    right: calc(50vw - 52rem);
}

.anteriorProductoGrande{
    left: calc(50vw - 52rem);
}

.botonConsultarMaquina.consultarMaquinaGrande{
    height: 4rem;
    width: 16rem;
    font-size: 2.3rem;
    margin-top: 0;
}

@media (max-width: 820px) {
    .parteUtilizableProductoGrande {
        width: 95rem;
        height: 63rem;
    }

    .logoProductoGrandeContainer {
        top: calc(50vh - 30rem);
        left: calc(50vw - 46rem);
    }

    .imagenProductoGrandeContainer {
        width: 60rem;
        height: 35rem;
    }

    .siguienteProductoGrande svg, .anteriorProductoGrande svg{
        width: 6rem;
        height: 6rem;
    }

    .siguienteProductoGrande{
        right: calc(50vw - 55rem);
    }
    
    .anteriorProductoGrande{
        left: calc(50vw - 55rem);
    }

    .botonConsultarMaquina.consultarMaquinaGrande{
        height: 5rem;
        width: 20rem;
        font-size: 2.9rem;
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .parteUtilizableProductoGrande {
        width: 97%;
        height: 64rem;
    }

    .informacion h1 {
        margin-bottom: 1rem;
    }

    .logoProductoGrandeContainer {
        left: calc(100vw - 100vw*96/100);
        top: calc(50vh - 30rem);
    }

    .imagenProductoGrandeContainer {
        width: 95%;
        height: 35rem;
    }

    .textoPesoPromedio .valorKg {
        font-weight: 700;
    }

    .colorProductoGrandeContainer .colorAtributo {
        font-weight: 700;
    }

    .cantidad {
        padding: 1rem 0 0 0;
    }

    .cantidadProductoGrande {
        margin: 0;
        font-size: 4.3rem;
        font-weight: 600;
    }

    .cantidad button {
        width: 4.5rem;
        height: 4.5rem;
        font-size: 4.5rem;
        font-weight: 600;
    }

    .parteInternaUtilizableProductoGrande {
        border-style: solid;
        border-image: linear-gradient(to right,
                transparent 0%,
                transparent 15%,
                white 15%,
                white 90%,
                transparent 90%,
                transparent 100%) 1;
        border-image-slice: 1;
    }
}

@media (max-width: 280px) {
    .parteUtilizableProductoGrande {
        height: 72rem;
    }

    .logoProductoGrandeContainer {
        top: calc(50vh - 34rem);
    }

    .muestraColorProductoGrande {
        width: 13rem;
        height: auto;
    }

    .kgCantidadColorContainer {
        width: 100%;
        align-items: center;
        padding: 0 1rem;
    }

    .kgProductoGrandeContainer {
        width: 41%;
    }

    .conjuntoCantidad {
        padding-top: 11rem;
        width: 35%;
    }

    .parteInternaUtilizableProductoGrande {
        border-style: solid;
        border-image: linear-gradient(to right,
                transparent 0%,
                transparent 18%,
                white 18%,
                white 90%,
                transparent 90%,
                transparent 100%) 1;
        border-image-slice: 1;
    }
}