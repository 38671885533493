.contenedorPrincipalCardMisCompras {
    width: 100%;
    margin-bottom: 1rem;
}

.headCardMisCompras {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 3px 5px -0px rgba(0,0,0,0.75);
    border-radius: 7px;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.fechaCardMisComprasContainer {
    width: 100%;
    background-color: var(--colorAzulOscuro);
    display: flex;
    justify-content: center;
    height: 3rem;
    align-items: center;
}

.fechaCardMisCompras {
    color: white;
    font-size: 1.8rem;
}

.divisorHeadMisCompras {
    background-color: var(--colorTerciario);
    height: 1px;
    width: 100%;
}

.productosHistorialContainer{
    padding-top: 1rem;
}

.cantidadYBotonesContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 7rem;
    padding: 0 4rem;
}

.cantidadYPoliginosContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.poligonoMisCompras {
    margin-right: 0.5rem;
    width: 1.8rem;
    height: 0.8rem;
    margin-bottom: 1rem;
}

.cantidadYPoliginosContainer h1 {
    font-size: 1.8rem;
    color: var(--colorRojo);
    font-weight: 600;
}

.botonesContainer {
    display: flex;
    flex-direction: column;
}

.botonesContainer button {
    width: 20rem;
    border: none;
    margin: 0.2rem 0;
    font-size: 1.5rem;
    font-weight: 600;
    border-radius: 4px;
}

.botonRepetirCompra {
    background-color: var(--colorRojo);
    color: white;
}

.bodyCardMisCompras {
    display: flex;
    overflow: hidden;
    position: relative;
    z-index: 1;
    background-color: white;
    flex-direction: column;
    border-radius: 0 0 7px 7px;
    background-color: var(--colorPrimario);
    width: 97%;
    margin-left: 1.5%;
    height: 1rem;
    transition: height 0.3s;
    overflow: hidden;
    interpolate-size: allow-keywords;
}

.bodyCardMisCompras.open {
    height: auto;
}

.bodyCardMisCompras::-webkit-scrollbar {
    width: 0;
}

.bodyCardMisCompras button{
    width: 25rem;
    margin: 1rem auto;
    height: 3.5rem;
    font-size: 1.8rem;
    background-color: #107c41;
    font-size: white;
    border: none;
    color: white;
    font-weight: 600;
    border-radius: 5px;
    transition: transform 0.1s;
}

.bodyCardMisCompras button:active{
    transform: scale(0.97);
}

.botonCollapseCardComprasContainer {
    display: flex;
    align-items: center;
}

.botonCollapseCardCompras {
    transition: background-color 0.2s, color 0.2s, transform 0.2s;
    background-color: var(--colorPrimario);
}

.botonCollapseCardCompras.open {
    background-color: var(--colorSecundario);
    color: white;
    transform: scale(0.98);
}

.totalContainer {
    display: flex;
    height: 3rem;
    background-color: var(--colorSecundario);
    align-items: center;
    justify-content: center;
}

.totalContainer h2 {
    text-align: center;
    color: white;
    font-size: 1.7rem;
}

.precioViejo{
    background-color: var(--colorSecundario);
    margin: 0;
}

.precioActual{
    background-color: var(--colorRojo);
}

@media(max-width: 820px){
    .productosHistorialContainer{
        padding-top: 1.7rem;
    }
}

@media(max-width: 767px){
    .fechaCardMisComprasContainer{
        height: 4rem;
    }

    .fechaCardMisComprasContainer h1{
        font-size: 2.2rem;
    }

    .cantidadYBotonesContainer{
        height: 13rem;
    }

    .cantidadYPoliginosContainer div{
        height: 1.3rem;
        width: 3rem;
        margin-bottom: 0.8rem;
    }

    .cantidadYBotonesContainer h1{
        font-size: 2.5rem;
    }

    .botonesContainer button{
        height: 5rem;
        width: 25rem;
        font-size: 2rem;
    }

    .totalContainer{
        height: 6.5rem;
    }

    .totalContainer h2{
        font-size: 2.2rem;
    }

    .bodyCardMisCompras button{
        width: 30rem;
        height: 5rem;
        font-size: 2.3rem;
    }

    .bodyCardMisCompras button svg{
        width: 2.3rem;
        height: 2.3rem;
    }
}