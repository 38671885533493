.contenedorPrincipalCardMuestra {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
}

.contenedorPrincipalCardMuestra p {
    font-size: 2rem;
    font-weight: 600;
    width: 90%;
    background-color: var(--colorTerciario);
    color: var(--colorSecundario);
    padding-left: 0.7rem;
}

.contenedorImagen {
    width: 90%;
    height: auto;
    overflow: hidden;
    position: relative;
    cursor: crosshair;
}

.contenedorImagen img {
    display: block;
    transition: transform 0.1s ease;
    width: 100%;
    height: auto;
}

.contenedorImagen:hover img {
    transform: scale(1.6);
}

@media(max-width: 820px){
    .contenedorImagen:hover img {
        transform: none;
    }
}

@media(max-width: 767px){
    .contenedorPrincipalCardMuestra p {
        height: 4rem;
        font-size: 3rem;
    }
}