.especificacionCortina.negro{
    background-color: black;
    color: white;
}

.especificacionCortina.blanco{
    border: 1px solid var(--colorSecundario);
    background-color: white;
}

.especificacionCortina.blanco:hover, .especificacionCortina.blanco.checked{
    color: var(--colorSecundario)
}

.especificacionCortina.beige{
    background-color: var(--Beige);
    color: black;
}

.especificacionCortina.beige, .especificacionCortina.beige.checked{
    color: black;
}

.especificacionCortina.gris, .especificacionCortina.gris.checked{
    color: var(--colorSecundario);
    background-color: var(--colorPrimario);
}

.especificacionCortina.gris, .especificacionCortina.beige, .especificacionCortina.negro, .especificacionCortina.blanco{
    width: 7rem;
    transition: scale 0.1s;
}

.especificacionCortina.negro.checked, .especificacionCortina.blanco.checked, .especificacionCortina.beige.checked, .especificacionCortina.gris.checked{
    transform: scale(0.9);
}

.shantungRosa{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/shantungRosa.jpg);
}

.shantungGris{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/shantungGris.jpg);
}

.parisBeige{
    background-color: #eed7b8;
}

.parisCeleste{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisCeleste.jpg);
}

.parisGrisAgua{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisGrisAgua.jpg);
}

.parisVerdeClaro{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisVerdeClaro.jpg);
} 

.parisBlancoTrans{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisBlancoTrans.jpg);
}

.parisBlanco{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisBlanco.jpg);
}

.parisCrema{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisCrema.jpg);
}

.parisNaranja{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisNaranja.jpg);
}

.parisRojo{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/parisRojo.jpg);
}

.okiataNegroBeige{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataNegroBeige.jpg);
}

.okiataBlanco{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataBlanco.jpg);
}

.okiataNegro{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataNegro.jpg);
}

.okiataBeige{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataBeige.jpg);
}

.okiataBlancoBeige{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataBlancoBeige.jpg);
}

.okiataBlancoGris{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataBlancoGris.jpg);
}

.okiataNegroBronce{
    background-image: url(https://storage.googleapis.com/backend-calvo-415917.appspot.com/coloresCortinas/okiataNegroBronce.jpg);
}

.visionBlanco{
    background-color: white;
}

.visionNegro{
    background-color: black;
}

.visionLila{
    background-color: #664c9c;
}

.accesorioBlanco, .blackoutBlanco{
    background-color: white;
}

.accesorioGris, .blackoutGris{
    background-color: #444b53;
}

.accesorioBeige, .blackoutBeige{
    background-color: #eed7b8;
}

.accesorioNegro, .blackoutNegro{
    background-color: black;
}

.columnasColores{
    display: flex;
    flex-direction: column !important;
}

.coloresArriba, .coloresMedio{
    margin-bottom: 1rem;
}