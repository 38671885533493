.contenedorPrincipalBodyPerfil{
    width: 100%;
    min-height: calc(100vh - 3rem);
    padding-top: 14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--colorSenario);
}

.nombreYEmailPerfilContainer{
    width: 50rem;
    height: 8rem;
    padding: 0 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    z-index: 3;
    background-color: var(--colorSecundario);
    border-radius: 4px;
    border: 0px solid var(--colorAzulOscuro);
}

.nombreYEmailPerfilContainer h1{
    font-weight: 600;
    text-align: center;
    font-size: 2.3rem;
    color: white;
}

.nombreYEmailPerfilContainer h2{
    color: var(--colorRojo);
    font-weight: 500;
    font-size: 2rem;
}

.contenedorPrincipalEditar{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50rem;
    margin: 0.5rem 0;
}

.headEditar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 5.5rem;
    flex-direction: column;
    border-radius: 3px 3px 0 0;
    overflow: hidden;
    background-color: var(--colorSenario);
    border-style: solid;
    border-width: 0 1px 0 1px;
    border-color: var(--colorSecundario);
}

.botonCollapseEditarContainer{
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.botonCollapseEditar{
    border: none;
    background-color: transparent;
}

.textoHeadEditar{
    cursor: pointer;
    background-color: var(--colorSecundario);
    color: white;
    width: 100%;
    text-align: center;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.textoHeadEditar h1{
    font-size: 1.6rem;
}

.form-group-editarPerfil{
    display: flex;
    flex-direction: column;
}

.form-group-editarPerfil input{
    height: 2.7rem;
    border: 2px solid var(--colorTerciario);
    padding: 0 0.5rem;
    font-weight: bold;
    color: var(--colorSecundario);
    font-size: 1.5rem;
}

.form-group-editarPerfil label{
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--colorSecundario);
}

.formularioPerfil{
    padding: 0 10rem;
}

.editarInformacion{
    height: 1px;
    background-color: white;
    display: flex;
    overflow: hidden;
    transition: height 0.3s;
    border-style: solid;
    border-color: var(--colorSecundario);
    border-width: 0 1px 2px 1px;
    border-radius: 0 0 3px 3px;
    width: 50rem;
}

.botonFormulariosPerfilContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.botonFormulariosPerfil{
    width: 15rem;
    height: 3rem;
    font-size: 1.7rem;
    border: none;
    color: white;
    background-color: var(--colorRojo);
    border-radius: 4px;
    font-weight: 600;
}

@media (max-width: 820px){
    .form-group-editarPerfil input{
        height: 3.5rem;
        font-size: 2rem;
    }

    .form-group-editarPerfil label{
        font-size: 2rem;
    }

    .nombreYEmailPerfilContainer{
        width: 60rem;
    }

    .nombreYEmailPerfilContainer h1{
        font-size: 3rem;
    }

    .nombreYEmailPerfilContainer h2{
        font-size: 2.5rem;
    }

    .contenedorPrincipalConfiguracion{
        width: 60rem;
    }
    
    .contenedorPrincipalEditar{
        width: 100%;
    }

    .editarInformacion{
        width: 100%;
    }

    .headEditar{
        height: 7rem;
    }

    .textoHeadEditar{
        height: 3.5rem;
    }

    .textoHeadEditar h1{
        font-size: 2rem;
    }

    .botonFormulariosPerfil{
        height: 4rem;
    }
}

@media (max-width: 767px){
    .contenedorPrincipalBodyPerfil{
        min-height: calc(100vh - 9rem)
    }

    .nombreYEmailPerfilContainer{
        height: 10rem;
    }

    .nombreYEmailPerfilContainer h1{
        font-size: 4rem;
    }

    .nombreYEmailPerfilContainer h2{
        font-size: 3.5rem;
    }

    .nombreYEmailPerfilContainer{
        width: 65rem;
    }

    .contenedorPrincipalConfiguracion{
        width: 65rem;
    }

    .textoHeadEditar{
        height: 5rem;
    }

    .textoHeadEditar h1{
        font-size: 3rem;
    }

    .botonCollapseEditar{
        width: 3rem;
        height: 3rem;
    }

    .botonCollapseEditar svg{
        width: 100%;
        height: 100%;
    }

    .form-group-editarPerfil label{
        font-size: 3rem;
    }

    .form-group-editarPerfil input{
        height: 5rem;
        font-size: 3rem;
    }

    .botonFormulariosPerfil{
        width: 23rem;
        height: 5.5rem;
        font-size: 2.5rem;
    }
}

@media (max-width: 280px){
    .nombreYEmailPerfilContainer{
        width: 50rem;
    }

    .contenedorPrincipalConfiguracion{
        width: 50rem;
    }
}