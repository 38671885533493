.colapsableEditarDatos{
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.colapsableEditarDatos.open{
    height: 26rem;
}

.formularioEditarDatos{
    width: 100%;
}

.formEditarDatos{
    padding: 0.5rem 0;
}

@media (max-width: 912px){
    .colapsableEditarDatos.open{
        height: 30rem;
    }
}

@media (max-width: 540px) {
    .colapsableEditarDatos.open{
        height: 40rem;
    }
}